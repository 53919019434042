const messages = {
  "calculationDetailsReport.title": "Berechnungsdetails",
  "calculationDetailsReport.generateReport": "Bericht erstellen",
  "calculationDetailsReport.generateXLSXReport": "Bericht erstellen (XLSX)",
  "calculationDetailsReport.type": "Typ",
  "calculationDetailsReport.from": "Von",
  "calculationDetailsReport.to": "Bis",
  "calculationDetailsReport.Date": "Datum",
  "calculationDetailsReport.Week": "Woche",
  "calculationDetailsReport.Month": "Monat",
  "calculationDetailsReport.Year": "Jahr",
}

export default messages
