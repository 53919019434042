/**
 * TODO: This shares alot with the PasswordChange component...should be unified.
 */

import React from "react"
import { connect } from "react-redux"
import { injectIntl } from "react-intl"
import { parse } from "query-string"

import {
  validatePasswordHandler,
  identicalPasswordsHandler,
} from "../lib/passwordValidation"
import RegistrationForm from "../components/RegistrationForm"
import makeTranslations from "../models/registrationFormModel"
import { createUserFromCandidateAsync } from "../redux/actions/candidateActions"
import { getRegistrationErrorState } from "../redux/selectors/candidateSelectors"

import wrapAppIntl from "../../../setup/loadIntl"
import withRouter from "../../../shared/withRouter"

const mapDispatchToProps = dispatch => {
  return {
    dispatchSave: (fields, navigate) => {
      dispatch(createUserFromCandidateAsync(fields)).then(user => {
        if (user.error !== true) {
          // We might want to redirect according to permissions again.
          navigate("/")
        }
      })
    },
  }
}

const mapStateToProps = state => ({
  registrationError: getRegistrationErrorState(state),
})

const RegistrationFormContainer = props => {
  const translations = makeTranslations(id => props.intl.formatMessage({ id }))

  const onSubmit = values => {
    const { dispatchSave, search } = props
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const urlParams = parse(search.toString())
    const urlToken = urlParams && urlParams.token ? urlParams.token : undefined
    dispatchSave(
      {
        urlToken,
        password: values.password,
      },
      props.navigate
    )
  }

  const validatePassword = async password => {
    if (password && password.length > 1) {
      const { isValid, score } = await validatePasswordHandler(password)
      if (isValid) {
        return {
          validateStatus: "success",
          errorMsg: null,
          score,
        }
      } else {
        return {
          validateStatus: "error",
          errorMsg: translations["registration.password-weak"],
          score,
        }
      }
    } else {
      return {
        validateStatus: "error",
        errorMsg: translations["users.error.missingField"],
      }
    }
  }

  const validateEquality = (password, firstPassword) => {
    const isEqual = identicalPasswordsHandler(firstPassword, password)
    if (isEqual) {
      return {
        validateStatus: "success",
        errorMsg: null,
      }
    } else {
      return {
        validateStatus: "error",
        errorMsg: translations["registration.password-unequal"],
      }
    }
  }

  return (
    <RegistrationForm
      translations={translations}
      onSubmitHandler={onSubmit}
      registrationError={props.registrationError}
      validatePassword={validatePassword}
      validateEquality={validateEquality}
    />
  )
}

const RegistrationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(RegistrationFormContainer)))

export default () => wrapAppIntl(RegistrationContainer)
