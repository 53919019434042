/* The HOC for handling login; wrapped around the LoginForm */

import React from "react"
import { connect } from "react-redux"

import LoginForm from "./LoginForm"
import {
  loginAsync,
  closeLoginDialog,
  openPasswordResetDialog,
} from "../redux/actions/loginActions"
import { mapStateToProps } from "../redux/utils/loginUtils"
import withRouter from "../../../shared/withRouter"

const mapDispatchToProps = dispatch => {
  return {
    dispatchLogin: (username, password, totp) =>
      dispatch(loginAsync(username, password, totp)),
    dispatchCloseLoginDialog: () => dispatch(closeLoginDialog()),
    dispatchOpenPasswordResetDialog: username =>
      dispatch(openPasswordResetDialog(username || "")),
  }
}

/**
 * HOC for the login form
 *
 * provides
 * - onCancel (callback when cancelling the form)
 *
 * Automatically wraps in
 * - injectIntl (props.intl)
 */
export const LoginFormContainer = props => {
  const onLogin = React.useCallback(
    values => {
      if (values.username && values.password) {
        props.dispatchLogin(values.username, values.password, values.token)
      }
    },
    [props]
  )

  const onCancel = React.useCallback(() => {
    props.dispatchCloseLoginDialog()
  }, [props])

  const onPasswordForgotten = React.useCallback(
    e => {
      e.stopPropagation()
      e.preventDefault()
      props.dispatchOpenPasswordResetDialog()
      return false
    },
    [props]
  )
  return (
    <LoginForm
      loginError={props.loginError} // Server error
      onLogin={onLogin}
      onCancel={onCancel}
      onPasswordForgotten={onPasswordForgotten}
      openPasswordResetDialog={props.openPasswordResetDialog}
    />
  )
}

const LoginFormContainerComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginFormContainer))

export default LoginFormContainerComponent
