import { valueAt, isEqual } from "../../../../shared/walkObjectHierarchy"
import types from "../actions/actionTypes"

export const STACKSIZE = 25 // how many orgunitIds will be saved in the stack?

export default function orgChartReducer(state = {}, action) {
  switch (action.type) {
    case types.SAVEORGUNIT:
      return saveOrgUnit(state, action)
    case types.ARCHIVEORGUNIT:
      return archiveOrgUnit(state, action)
    case types.RESTOREORGUNIT:
      return restoreOrgUnit(state, action)
    case types.DELETEORGUNIT:
      return deleteOrgUnit(state, action)
    case types.TOGGLEINCLUDEARCHIVED:
      return toggleIncludeArchived(state, action)
    case types.INSERTTRANSIENTORGUNIT:
      return {
        ...state,
        transientNodeFor: action.parentId,
        changeContextTo: "new",
        readOnly: false,
      }
    case types.SAVEORGUNITFAILED:
      return {
        ...state,
        saveFailed: true,
        saveError: action.error,
      }
    case types.LOADALLORGUNITSFAILED:
      return {
        ...state,
        loadFailed: true,
        loadError: action.error,
      }
    case types.LOADALLORGUNITS:
      return loadAll(state, action)
    case types.LOADORGUNITDETAILS:
      return {
        ...state,
        details: action.details,
      }
    case types.APPENDTOSTACK:
      return appendToStack(state, action)
    case types.RESETSTACK:
      return {
        ...state,
        stack: [],
        changeContextTo: undefined,
      }
    case types.POPFROMSTACK:
      return popFromStack(state, action)
    case types.SETREADONLY:
      return {
        ...state,
        readOnly: action.readOnly,
      }
    case types.SWITCHORDER:
      return {
        ...state,
        hierarchyChanged: new Date(),
      }
    default:
      return state
  }
}

function loadAll(state, action) {
  const byId = action.allOrgUnits.reduce((acc, orgUnit) => {
    acc[orgUnit.id] = orgUnit
    return acc
  }, {})
  const oldById = valueAt(state, ["orgUnits", "byId"]) || {}
  if (isEqual(oldById, byId)) {
    return state
  }
  return {
    ...state,
    hierarchyChanged: new Date(),
    orgUnits: { byId },
  }
}

function saveOrgUnit(state, action) {
  const orgUnits = valueAt(state, ["orgUnits"]) || {}
  const byId = valueAt(state, ["orgUnits", "byId"]) || {}
  const id = action.data.json.id
  const parentId = action.data.parentId
  const oldParentChildren =
    valueAt(state, ["orgUnits", "byId", parentId, "children"]) || []
  const newChildren = oldParentChildren.includes(id)
    ? oldParentChildren
    : [...oldParentChildren, id]
  const newReadOnly = action.data.addTransientNodeFor
    ? false
    : action.data.changeContextTo === "new"
    ? false
    : true
  return {
    ...state,
    hierarchyChanged: new Date(),
    transientNodeFor: action.data.addTransientNodeForChild,
    changeContextTo: action.data.changeContextTo,
    readOnly: newReadOnly,
    orgUnits: {
      ...orgUnits,
      byId: {
        ...byId,
        [parentId]: {
          ...byId[parentId],
          children: newChildren,
        },
        [id]: action.data.json,
      },
    },
  }
}

function archiveOrgUnit(state, action) {
  const orgUnits = valueAt(state, ["orgUnits"]) || {}
  const byId = valueAt(state, ["orgUnits", "byId"]) || {}
  const orgUnit = byId[action.orgunitId]
  // XXX: change context to next non-archived parent?
  return {
    ...state,
    hierarchyChanged: new Date(),
    readOnly: true,
    orgUnits: {
      ...orgUnits,
      byId: {
        ...byId,
        [action.orgunitId]: {
          ...orgUnit,
          isArchived: true,
        },
      },
    },
  }
}

function restoreOrgUnit(state, action) {
  const orgUnits = valueAt(state, ["orgUnits"]) || {}
  const byId = valueAt(state, ["orgUnits", "byId"]) || {}
  const orgUnit = byId[action.orgunitId]
  return {
    ...state,
    hierarchyChanged: new Date(),
    orgUnits: {
      ...orgUnits,
      byId: {
        ...byId,
        [action.orgunitId]: {
          ...orgUnit,
          isArchived: false,
        },
      },
    },
  }
}

function deleteOrgUnit(state, action) {
  const orgUnits = valueAt(state, ["orgUnits"]) || {}
  const byIdState = valueAt(state, ["orgUnits", "byId"]) || {}
  const byId = { ...byIdState }
  delete byId[action.orgunitId]
  // XXX: change context to next non-archived parent?
  return {
    ...state,
    hierarchyChanged: new Date(),
    readOnly: true,
    orgUnits: {
      ...orgUnits,
      byId,
    },
  }
}

function toggleIncludeArchived(state) {
  const includeArchived = valueAt(state, ["includeArchived"]) || false
  return {
    ...state,
    includeArchived: !includeArchived,
  }
}

function appendToStack(state, action) {
  const stack = valueAt(state, ["stack"]) || []
  // We really need to find a common ground for id types...
  const orgunitId = "" + action.orgunitId
  let newStack = [...stack]
  if (orgunitId !== "" + newStack[newStack.length - 1]) {
    newStack.push(orgunitId)
  }
  if (newStack.length > STACKSIZE) {
    newStack = newStack.slice(newStack.length - STACKSIZE)
  }
  return {
    ...state,
    stack: newStack,
    changeContextTo: undefined,
  }
}

function popFromStack(state) {
  const stack = valueAt(state, ["stack"]) || []
  let newStack = [...stack]
  if (stack.length > 1) {
    newStack = newStack.slice(0, newStack.length - 2)
  }
  return {
    ...state,
    stack: newStack,
    changeContextTo: undefined,
  }
}
