/* eslint-disable max-len */
const messages = {
  "infoPages.heading": "Kontoinfo {clientName}",
  "infoPages.month": "Abrechnungsmonat",
  "infoPages.totalUsers": "Gesamt Benutzer",
  "infoPages.activeUsers": "Aktive Benutzer",
  "infoPages.lockedUsers": "Gesperrte Benutzer",
  "infoPages.hiddenUsers": "Versteckte Benutzer",
  "infoPages.deletedUsers": "Gelöschte Benutzer",
  "infoPages.archivedUsers": "Archivierte Benutzer",
  "infoPages.licensedUsers": "Lizensierte Benutzer",
  "infoPages.licenseVolume": "Lizenzvolumen",
  "infoPages.currentlyInUse": "Aktuell verwendet",
  "infoPages.stillRemaining": "Noch verfügbar",
  "infoPages.exceededLicense":
    "Das vereinbartes Lizenzvolumen wurde überschritten. Bitte wenden Sie sich an Mina.Works zur Anpassung Ihres Lizenzvolumens.",
  "infoPages.clientInfos": "Stammdaten",
  "infoPages.licensInformations": "Lizenzinformationen",
  "infoPages.contactInfos": "Hinterlegte Kontaktdaten:",
  "infoPages.phone": "Telefon:",
  "infoPages.email": "E-Mail:",
  "infoPages.effectiveusers": "Anzahl Benutzer",
  "infoPages.billedusers": "Anzahl abgerechnete Benutzer",
  "infoPages.effectivedate": "Gültig von",
  "infoPages.expirydate": "Gültig bis",
  "infoPages.licensesTable": "Abrechnungen",
  "infoPages.nolicense": "keine Lizenz",
  "infoPages.currentStats": "Aktuelle Kennzahlen zur Nutzung",
  "infoPages.currentStats.description":
    'Mitarbeiter können gleichzeitig "gesperrt", "versteckt", und "archiviert" sein. Die Summe der hier angezeigten Mitarbeiter muss daher nicht identisch zu der Summe aller Mitarbeiter sein. Nur "Archivierte" Mitarbeiter geben Lizenzen wieder frei.',
  "infoPages.keyFiguresLicences": "Lizenzen",
  "infoPages.keyFiguresUsers": "Mitarbeiter",
  "infoPages.keyFiguresCalculationProfiles": "Tarife",
}

export default messages
