import React, { Component } from "react"
import { connect } from "react-redux"
import { injectIntl } from "react-intl"
// import { push } from "connected-react-router"

import { mapStateToProps } from "../redux/utils/loginUtils"
import { openLoginDialog, logoutAsync } from "../redux/actions/loginActions"
import { changeLanguageAsync } from "../../languagePicker/redux/actions/languagePickerActions"

import makeTranslations from "../models/userMenuModel.js"
import UserMenu from "../components/UserMenu"
import ProblemReportUtil from "../../../shared/ProblemReportUtil"
import withRouter from "../../../shared/withRouter"

class UserMenuContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.checkProblems = this.checkProblems.bind(this)
    this.clearProblems = this.clearProblems.bind(this)
    this.downloadProblems = this.downloadProblems.bind(this)
    this.sendProblems = this.sendProblems.bind(this)
  }

  componentDidMount() {
    this.renewProblemReportUtil()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.username !== this.props.username) {
      this.renewProblemReportUtil()
    }
  }

  componentWillUnmount() {
    this.problemReportUtil?.stop()
  }

  render() {
    const { username } = this.props
    const t = id => this.props.intl.formatMessage({ id }, { username })
    const changeLanguageHandler = language => {
      this.props.changeLanguage(language, this.props.currentUserId)
    }
    const languageMappings = createLanguageMappings()
    const langOptions = buildLanguageOptions({
      intl: this.props.intl,
      currentLanguage: this.props.currentLanguage,
      languageMappings,
    })
    return (
      <UserMenu
        context={this.props.context}
        isAdmin={this.props.isAdmin}
        problems={this.state.problems}
        changeAppModule={url =>
          this.props.changeAppModule(url, this.props.navigate)
        }
        translations={makeTranslations(t)}
        username={username}
        openLoginDialog={this.props.openLoginDialog}
        logout={() => this.props.logout(this.props.navigate)}
        isLoggedIn={this.props.isLoggedIn}
        canChangePassword={this.props.canChangePassword}
        canViewOwnData={this.props.canViewOwnData}
        canEditClientOptions={this.props.canEditClientOptions}
        changeLanguageHandler={changeLanguageHandler}
        langOptions={langOptions}
        downloadProblems={this.downloadProblems}
        sendProblems={this.sendProblems}
        clearProblems={this.clearProblems}
      />
    )
  }

  renewProblemReportUtil() {
    this.problemReportUtil?.stop()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.problemReportUtil = new ProblemReportUtil(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.props.username,
      `${this.props.context.clientId}`,
      () => {
        const problems = this.problemReportUtil?.getProblems()
        if (JSON.stringify(this.state.problems) !== JSON.stringify(problems)) {
          this.setState({ problems })
        }
      }
    )
    this.problemReportUtil.start()
  }

  sendProblems() {
    this.problemReportUtil.sendProblems()
  }

  downloadProblems() {
    this.problemReportUtil.downloadProblems()
  }

  checkProblems() {
    this.problemReportUtil.checkProblems()
  }

  clearProblems() {
    if (
      window.confirm(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.props.intl.formatMessage({ id: "really-delete-problem-report" })
      )
    ) {
      this.problemReportUtil.clearProblems()
    }
  }
}

function buildLanguageOptions({ intl, currentLanguage, languageMappings }) {
  return languageMappings.map(({ shortName, messageId, flag }) => ({
    key: shortName,
    value: shortName,
    text: shortName.toUpperCase(),
    flag,
    title: intl.formatMessage({ id: messageId }),
    selected: shortName === currentLanguage,
  }))
}

function createLanguageMappings() {
  // TODO: read from config!
  return [
    {
      shortName: "de",
      messageId: "lang.german",
      flag: "de",
    },
    {
      shortName: "en",
      messageId: "lang.english",
      flag: "gb",
    },
  ]
}

const mapDispatchToProps = dispatch => {
  return {
    openLoginDialog: () => dispatch(openLoginDialog()),
    logout: navigate => dispatch(logoutAsync("/", navigate)),
    changeAppModule: (url, navigate) => {
      navigate(url)
      // dispatch(push(url))
    },
    changeLanguage: (language, userId) => {
      dispatch(changeLanguageAsync(language, userId))
    },
  }
}

const UserMenuContainerComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(UserMenuContainer)))

export default UserMenuContainerComponent
