import types from "../actions/actionTypes"

export default function userEditReducer(state = {}, action) {
  switch (action.type) {
    case types.OPENEDITUSERDIALOG:
      return {
        ...state,
        openEditUserDialog: true,
        editUserId: action.stateId,
      }
    case types.CLOSEEDITUSERDIALOG:
      return {
        ...state,
        openEditUserDialog: false,
        editUserId: undefined,
        entryYear: undefined,
        defaultTab: undefined,
      }
    case types.SETDEFAULTTAB:
      return {
        ...state,
        defaultTab: action.tab,
      }
    case types.BATCHRESTOREERROR:
      return batchRestoreError(state, action)
    case types.BATCHSUCCESS:
      return {
        ...state,
        openBatchRestoreModal: false,
        userIds: undefined,
      }
    case types.OPENBATCHRESTOREMODAL:
      return {
        ...state,
        openBatchRestoreModal: true,
        userIds: action.userIds,
      }
    case types.CLOSEBATCHRESTOREMODAL:
      return {
        ...state,
        openBatchRestoreModal: false,
        userIds: undefined,
      }
    case types.LOADUSERENTRYYEAR:
      return {
        ...state,
        entryYear: action.year,
      }
    default:
      return state
  }
}

function batchRestoreError(state, action) {
  // If the error has a list of users, we can change the userIds
  // to only the ones with errors, the others are done already.
  if (action.error && action.error.users) {
    const newUserIds = action.error.users.map(u => u.userId)
    return {
      ...state,
      batchRestoreError: action.error,
      userIds: newUserIds,
    }
  } else {
    return {
      ...state,
      batchRestoreError: action.error,
    }
  }
}
