const messages = {
  "calculationDetailsReport.title": "Calculation details",
  "calculationDetailsReport.generateReport": "Generate report",
  "calculationDetailsReport.generateXLSXReport": "Generate report (XLSX)",
  "calculationDetailsReport.type": "Type",
  "calculationDetailsReport.from": "From",
  "calculationDetailsReport.to": "To",
  "calculationDetailsReport.Date": "Date",
  "calculationDetailsReport.Week": "Week",
  "calculationDetailsReport.Month": "Month",
  "calculationDetailsReport.Year": "Year",
}

export default messages
