export default function makeTranslations(t) {
  return [
    "registration.heading",
    "save",
    "registration.password",
    "registration.password-repeat",
    "registration.password-weak",
    "registration.password-unequal",
    "registration.decodeTokenError",
    "registration.registrationError",
    "registration.MissingCandidate",
    "users.error.missingField",
    "registration.password-weak",
    "registration.password-unequal",
    "pages.registration.title",
    "pages.registration.description",
  ].reduce((acc, k) => ({ ...acc, [k]: t(k) }), {})
}
