import type { AnyAction } from "redux"
import types from "../actions/actionTypes"

export default function (state = {}, action: AnyAction): any {
  switch (action.type) {
    case types.LOADCALCULATIONDETAILSREPORTID:
      return {
        ...state,
        reportId: action.id,
      }
    default:
      return state
  }
}
