/**
 * Check whether the current user can see this process type
 */

// From backend
interface WorkflowType {
  version: number
  description: string
  workflow_type: string
}

export async function getAvailableProcessTypes(
  departmentId: string
): Promise<string[]> {
  const res = await fetch(
    makeURL("/rest/workflows/getAllWorkflowTypesForUser", departmentId ?? ""),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  )
  const types = (await res.json()) as WorkflowType[] | undefined
  try {
    return (types ?? []).map(({ workflow_type }) => workflow_type)
  } catch (e) {
    alert("Ein Fehler ist aufgetreten!")
    return []
  }
}

export default async function checkAvailableProcessType(
  typeName: string,
  departmentId: string
): Promise<boolean> {
  const res = await fetch(
    makeURL("/rest/workflows/getAllWorkflowTypesForUser", departmentId ?? ""),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  )
  const types = (await res.json()) as WorkflowType[] | undefined
  try {
    return !!types?.find(t => t.workflow_type === typeName)
  } catch (e) {
    alert("Ein Fehler ist aufgetreten!")
    return false
  }
}

function makeURL(path: string, departmentId: string) {
  const url =
    path +
    "?" +
    new URLSearchParams({
      orgunitId: departmentId,
      contextOrgunitId: departmentId,
    }).toString()
  return url
}
