/* The HOC for handling login; wrapped around the LoginModal */

import React from "react"
import { connect } from "react-redux"

import LoginModal from "../components/LoginModal"
import { closeLoginDialog } from "../redux/actions/loginActions"
import { mapStateToProps } from "../redux/utils/loginUtils"

const mapDispatchToProps = dispatch => {
  return {
    dispatchCloseLoginDialog: () => dispatch(closeLoginDialog()),
  }
}

/**
 * HOC for the login modal
 *
 * provides
 * - onCancel (callback when cancelling the form)
 *
 * Automatically wraps in
 * - injectIntl (props.intl)
 */
const LoginModalContainer = props => {
  const onCancel = React.useCallback(() => {
    props.dispatchCloseLoginDialog()
  }, [props])
  return (
    <LoginModal openLoginDialog={props.openLoginDialog} onCancel={onCancel} />
  )
}
// ,
// (props, nextProps) => {
//   return props.loginError !== nextProps.loginError
// }

const Wrapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginModalContainer)
export default Wrapped
