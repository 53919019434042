import types from "./actionTypes"

export default function usernameEditReducer(state = {}, action) {
  switch (action.type) {
    case types.LOADUSERNAME:
      return {
        ...state,
        username: action.username,
        feedback: undefined,
      }
    case types.LOADFEEDBACK:
      return {
        ...state,
        feedback: action.feedback,
        username: action.feedback.username,
      }
    default:
      return state
  }
}
