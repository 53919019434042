import urlBase64ToUint8Array from "./lib/urlBase64ToUint8Array"

const VAPIDPUBLICKEYURL = "/rest/webpush/getVapidPublicKey"
const REGISTERURL = "/rest/webpush/savePushSubscription"

let singleton: WebPushNotifications

/**
 * This class manages WebPushNotifications; instantiate it on the fly to tie
 * everything together. Can be used as a singleton object if necessary.
 */
export default class WebPushNotifications {
  /**
   * Get singleton instance; probably unnecessary, but may provide a minor speed
   * boost.
   */
  public static get(): WebPushNotifications {
    if (!singleton) {
      singleton = new WebPushNotifications()
    }
    return singleton
  }

  /**
   * Ask the user for notification permissions (if necessary)
   *
   * PLEASE NOTE:
   * If you do this at an inopportune time and the user clicks "Block",
   * you'll never get them back (easily). You cannot unblock a user from
   * within the web application!
   *
   * Best to react to user interaction (for future-proofing and to further
   * avoid the user blocking notifications.)
   */
  public askForPermission(subscribe: boolean = false): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        let alreadyProcessed = false
        const resolveCallback = (res: string) => {
          void (async () => {
            if (!alreadyProcessed) {
              alreadyProcessed = true
              const granted = res === "granted"
              if (subscribe && granted) {
                await this.subscribe()
              }
              resolve(granted)
            }
          })()
        }
        // There are two variants of this actively in use, so...:
        const p = Notification.requestPermission(resolveCallback)
        if (p !== undefined) {
          p.then(resolveCallback).catch(reject)
        }
      } catch (error) {
        reject(error as Error)
      }
    })
  }

  public async subscribe(): Promise<MaybePushSubscription> {
    const publicKey = await this.getVAPIDPublicKey()
    if (publicKey) {
      const options = {
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(publicKey as string),
      }
      const registration = await this.getSWRegistration()
      const granted = await this.askForPermission(false)
      if (registration?.pushManager && granted) {
        let sub: PushSubscription | null = null
        try {
          sub = await registration.pushManager.getSubscription()
          const applicationServerKey = sub?.options?.applicationServerKey
          const subKey = applicationServerKey
            ? new DataView(applicationServerKey)
            : undefined
          const appKey = new DataView(
            options.applicationServerKey?.buffer ?? new ArrayBuffer(0)
          )
          let equal = true
          if (subKey) {
            for (let i = 0; i < subKey?.byteLength; i++) {
              if (subKey?.getUint8(i) !== appKey.getUint8(i)) {
                equal = false
                break
              }
            }
          }
          if (!equal) {
            await sub?.unsubscribe()
            sub = null
            console.log("Unsubscribing from old key")
          } else {
            console.log("Existing subscription found; not subscribing")
          }
        } catch (e) {
          console.log("Error looking for existing subscription:", e)
        }
        if (sub === undefined || sub === null) {
          sub = await registration.pushManager.subscribe(options)
          await this.registerPushNotifications(sub)
        }
        return sub
      }
    }
    return null
  }

  public async getSubscription(
    subscribe: boolean = false
  ): Promise<MaybePushSubscription | undefined> {
    const registration = await this.getSWRegistration()
    let sub: MaybePushSubscription | null | undefined =
      await registration?.pushManager.getSubscription()
    if (sub === null && subscribe) {
      sub = await this.subscribe()
    }
    return sub
  }

  private async getSWRegistration() {
    return navigator.serviceWorker.getRegistration()
  }

  private async getVAPIDPublicKey() {
    const res = await fetch(VAPIDPUBLICKEYURL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    const json = await res.json()
    return json
  }

  private async registerPushNotifications(subscription: PushSubscription) {
    const res = await fetch(REGISTERURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(subscription),
    })
    const json = await res.json()
    return json.publicKey
  }
}

type MaybePushSubscription = PushSubscription | null
