import {
  dayjsToLocalDateString,
  localDateStringToDayjs,
  makeDateWrapperFromDate,
  makeDateWrapperFromString
} from "@mina-works/datetime-utils"
import type { Dayjs } from "dayjs"

const l10n: Record<string, Record<string, string>> = {
  de: {
    today: 'Heute'
  }
}
const i18n = (msgId: string, locale: string): string => (
  l10n[locale.slice(0, 2)][msgId] ?? msgId
)

/**
 * Return a short-ish date representation with short month and weekday names
 */
export function makeNiceShortLocaleDateStringFromISOString(
  date: string,
  locale: string,
  useTodayPrefix = false
): string {
  assertISODate(date)
  const d = makeDateFromISOString(date).toLocaleDateString(
    locale,
    {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    }
  )
  const prefix = `${useTodayPrefix && isToday(date) ? i18n('today', locale) : ''}`
  return `${prefix}${prefix !== '' ? ', ' : ''}${d}`
}

export function getDayBeforeISOStrings(date: string): string {
  const d = makeDateFromISOString(date)
  return isoStringFromDate(new Date(d.getFullYear(), d.getMonth(), d.getDate() - 1))
}

export function getDayAfterISOStrings(date: string): string {
  const d = makeDateFromISOString(date)
  return isoStringFromDate(new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1))
}

/**
 * date2 follows directly after date1
 */
export function dateFollowsISOStrings(date1: string, date2?: string): boolean {
  if (date2 !== undefined) {
    return getDayBeforeISOStrings(date2) === date1
  }
  return false
}

export function isToday(iso: string): boolean {
  return iso === isoStringFromDate(new Date())
}

export function isoStringFromDate(d?: Date): string {
  const date = d ?? new Date()
  return makeDateWrapperFromDate(date).isoDate
}

export function makeDateFromISOString(s: string): Date {
  assertISODate(s)
  return makeDateWrapperFromString(s).jsDate
  // const [y, m, d] = s.split('-').map(x => parseInt(x, 10))
  // return new Date(y, m - 1, d)
}

export function isoStringFromDayjs(d?: Dayjs): string | undefined {
  return d ? dayjsToLocalDateString(d) : undefined // d?.format('YYYY-MM-DD')
}

export function makeDayjsFromISOString(s: string): Dayjs {
  return localDateStringToDayjs(s)
  // return dayjs(makeDateFromISOString(s))
}

export function isoDateToSortableNumber(date: string): number {
  assertISODate(date)
  return parseInt(date.split('-').join(''), 10)
}

function assertISODate(date: string): void {
  console.assert(/^\d\d\d\d-\d\d-\d\d$/.test(date))
}

export function minutesToHoursString(minutes: number): string {
  const h = `${Math.floor(minutes / 60)}`.padStart(2, '0')
  const m = `${Math.round(minutes % 60)}`.padStart(2, '0')
  return `${h}:${m}`
}