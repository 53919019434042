import * as React from "react"
import { Input, Button, Alert, Form, Space } from "antd"
import { UserOutlined, LockOutlined } from "@ant-design/icons"
import { useIntl } from "react-intl"
import { post } from "../../../apps/shared/lib/requestUtils"

/**
 * LoginForm is the form inside LoginModal, a modal popup
 *
 * It has no logic associated with it directly; all login logic is handled by
 * the modal's container.
 */
const LoginForm = ({
  loginError,
  onLogin,
  onPasswordForgotten,
  openPasswordResetDialog,
}) => {
  const intl = useIntl()
  const [form] = Form.useForm()
  const [otp, setOtp] = React.useState()
  const [otpCheck, setOtpCheck] = React.useState({
    performed: false,
    needsotp: false,
  })
  const loginCallback = React.useCallback(() => {
    return onLogin({
      username: (form.getFieldValue("username") ?? "").trim(),
      password: (form.getFieldValue("password") ?? "").trim(),
      token: otp,
    })
  }, [form, onLogin, otp])
  const step1Callback = React.useCallback(() => {
    void (async () => {
      const { needsotp } = await post("/rest/authentication/loginneedsotp", {
        username: (form.getFieldValue("username") ?? "").trim(),
        password: (form.getFieldValue("password") ?? "").trim(),
      })
      setOtpCheck({ performed: true, needsotp })
    })()
  }, [form])
  const loginErrorRef = React.useRef()
  React.useEffect(() => {
    const prevLoginError = loginErrorRef.current
    loginErrorRef.current = loginError
    if (prevLoginError !== loginError && loginError.success === false) {
      form.resetFields()
      setOtpCheck({ performed: false, needsotp: false })
    }
  }, [form, loginError])
  React.useEffect(() => {
    if (!otpCheck.needsotp) {
      setOtp(undefined)
    }
  }, [otpCheck.needsotp])
  React.useEffect(() => {
    if (otpCheck.performed && !otpCheck.needsotp) {
      loginCallback()
    }
  }, [form, loginCallback, otpCheck, otpCheck.needsotp, otpCheck.performed])
  React.useEffect(() => {
    const handler = e => {
      if (e.key === "Enter") {
        if (otpCheck.needsotp) {
          loginCallback()
        } else {
          step1Callback()
        }
      }
    }
    window.addEventListener("keyup", handler)
    return () => window.removeEventListener("keyup", handler)
  }, [loginCallback, otpCheck.needsotp, step1Callback])
  return (
    <>
      {loginError &&
      loginError.success === false &&
      loginError.message?.startsWith("token-error-") ? (
        <div>
          <Alert
            message={intl.formatMessage({ id: "loginErrorHeader" })}
            description={intl.formatMessage({ id: loginError.message })}
            type="error"
          />
          <br />
        </div>
      ) : loginError && loginError.success === false ? (
        <div>
          <Alert
            message={intl.formatMessage({ id: "loginErrorHeader" })}
            description={intl.formatMessage({ id: "loginErrorMessage" })}
            type="error"
          />
          <br />
        </div>
      ) : null}
      <div className="login-header">
        <div className="login-header-text">Herzlich Willkommen bei</div>
        <img src="/mina-works-icon-w.png" alt="mina.works"></img>
      </div>

      <Form
        style={{
          display: otpCheck.needsotp || otp ? "none" : "block",
        }}
        className="login-form"
        form={form}
        layout="vertical"
      >
        <Form.Item
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: "usernameMissing" }),
            },
          ]}
          name="username"
        >
          <Input
            autoFocus={openPasswordResetDialog ? false : true}
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder={intl.formatMessage({ id: "username" })}
            data-e2e-test-id="login-username"
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: "passwordMissing" }),
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder={intl.formatMessage({ id: "password" })}
            data-e2e-test-id="login-password"
          />
        </Form.Item>
        <Form.Item className="login-button-row">
          <Button
            type="primary"
            data-e2e-test-id="login-submit1"
            onClick={step1Callback}
            disabled={otpCheck.needsotp}
          >
            {intl.formatMessage({ id: "login" })}
          </Button>

          <Button
            type="link"
            className="request-password-reset"
            onClick={onPasswordForgotten}
          >
            {intl.formatMessage({ id: "passwordForgotten" })}
          </Button>
        </Form.Item>
      </Form>
      {otpCheck.needsotp ? (
        <div className="login-otp-check">
          <h5>{intl.formatMessage({ id: "secondfactor" })}:</h5>
          <Space.Compact size="large">
            <Input
              value={otp}
              onChange={e => setOtp(e.target.value)}
              placeholder="Token"
              style={{ width: 120 }}
              autoFocus={true}
            ></Input>
            <Button
              type="primary"
              data-e2e-test-id="login-submit2"
              onClick={loginCallback}
            >
              {intl.formatMessage({ id: "login" })}
            </Button>
          </Space.Compact>
          <p>{intl.formatMessage({ id: "enter-second-factor" })}</p>
        </div>
      ) : null}
    </>
  )
}

export default LoginForm
