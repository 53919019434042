import React from "react"
import type { ITransaction } from "../../commonInterfaces/processes/ITransaction"
import { post } from "../lib/requestUtils"

const MARKREADAFTER = 500

export default function MarkAsRead({
  entry,
}: {
  entry: ITransaction
}): JSX.Element {
  React.useEffect(() => {
    const cb = () => {
      if (entry.transactionId && !entry.read) {
        console.log("Calling markTransactionAsRead for", entry)
        void post("/rest/workflows/markTransitionAsRead", {
          transactionId: entry.transactionId,
          async: false,
        })
      } else {
        console.log("NOT calling markTransactionAsRead for", entry)
      }
    }
    const t = window.setTimeout(cb, MARKREADAFTER)
    return () => window.clearTimeout(t)
  })
  return <></>
}
