/**
 * 24 hour representation of time in a day
 *
 * Please note: 0:00 is *usually* the start of the day,
 * but *may* be interpreted as the end of the day in some
 * cases. If you want to specify the end of the day without
 * any doubt, use 24:00
 */
export default class PlannerTime {

  constructor(private hour: number, private minute: number) { }

  static fromJSON(s: string): PlannerTime {
    const [h, m] = s.split(':').map(v => safeParseInt(v))
    return new this(h, m)
  }

  /**
   * WARNING: Defaults to 0:00 for (slightly dangerous) convenience
   */
  static fromObject({
    hour, minute
  }: {
    hour?: number
    minute?: number
  } = {}): PlannerTime {
    return new this(hour ?? 0, minute ?? 0)
  }

  getHour(): number { return this.hour }
  getMinute(): number { return this.minute }

  equals(other?: PlannerTime): boolean {
    return !!(other
      && this.getHour() === other?.getHour()
      && this.getMinute() === other?.getMinute()
    )
  }

  lt(other?: PlannerTime): boolean {
    if (!other) {
      return false // special case
    }
    return this.getHour() < other.getHour()
      || (
        this.getHour() === other.getHour()
        && this.getMinute() < other.getMinute()
      )
  }

  gt(other?: PlannerTime): boolean {
    return !!other && other.lt(this)
  }

  lte(other?: PlannerTime): boolean {
    return this.lt(other) || this.equals(other)
  }

  gte(other?: PlannerTime): boolean {
    return this.gt(other) || this.equals(other)
  }

  toString(): string {
    const h = `${this.hour}`.padStart(2, '0')
    const m = `${this.minute}`.padStart(2, '0')
    return `${h}:${m}`
  }

  toJSON(): string {
    return this.toString()
  }

  toObject(): { hour: number, minute: number } {
    return { hour: this.hour, minute: this.minute }
  }

  incrementHour(steps = 1): PlannerTime {
    let hour = this.hour + steps
    if (hour > 24) {
      hour -= 24
    }
    return new PlannerTime(hour, this.minute)
  }

}

/**
 * Note: This defaults to 0 for things that are not ints
 */
function safeParseInt(s: string): number {
  const trimmed = s.trim()
  return trimmed.match(/^[0-9]+$/) ? parseInt(trimmed, 10) : 0
}
