/* eslint-disable max-len */
export default {
  "compact-reference": "Funktionsreferenz",
  "AI Log": "KI-Interaktionen",
  Planung: "Planung",
  Berichte: "Berichte",
  Meldungen: "Meldungen",
  Verwaltung: "Verwaltung",
  Feiertage: "Feiertage",
  Mandantenverwaltung: "Mandantenverwaltung",
  Administratoren: "Administratoren",
  InfoMessages: "Info-Nachrichten",
  PackageOverview: "Feature-Pakete",
  Telemetrie: "Telemetrie",
  Planer: "Planer",
  Planbausteine: "Planbausteine",
  Autopläne: "Autopläne",
  Zeitkonto: "Zeitkonto",
  ZeitkontoBonuses: "Zuschläge",
  Wochenplan: "Wochenplan",
  Fehlzeitenübersicht: "Fehlzeitenübersicht",
  Urlaubsliste: "Urlaubsliste",
  Urlaubskonto: "Urlaubskonto",
  Jahresübersicht: "Jahresübersicht",
  Berechnungsdetails: "Berechnungsdetails",
  Organigramm: "Organigramm",
  Mitarbeiter: "Mitarbeiter",
  Qualifikationen: "Qualifikationen",
  Kalendereintragstypen: "Kalendereintragstypen",
  Tarife: "Tarife",
  "Tarife (V2)": "Tarife (V2)",
  Berechtigungen: "Berechtigungen",
  Kontoinformationen: "Kontoinfo",
  "users-active": "aktive Nutzer",
  Offline: "Offline",
  VerySlowNetwork: "Netzwerkprobleme",
  "problem-report": "Problembericht",
  "problem-report-title-preamble":
    "Probleme bei Aufrufen des mina.works-Servers",
  "problem-report-title-expl":
    "Diese Probleme können durch Netzwerkprobleme hervorgerufen werden.",
  "problem-report-title-who":
    "Wenn Sie den Eindruck haben, dass es Probleme in mina.works gibt, können Sie den mina.works-Support kontaktieren und ihm die entsprechenden Informationen per Mail oder anderweitig zukommen lassen.",
  "problem-report-title-ignore":
    "Wenn Sie in Ihrer Arbeit nicht beeinträchtigt werden oder von Netzwerkproblemen ausgehen, können Sie den Problembericht auch einfach ignorieren und ggf. löschen.",
  "mainmenu.missing-client": "Bitte einen Mandanten auswählen",
}
