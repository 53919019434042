import * as React from "react"
import {
  CalendarOutlined,
  ClockCircleOutlined,
  HomeOutlined,
  SnippetsOutlined,
} from "@ant-design/icons"
import { useIntl } from "react-intl"
import VacationDuotone from "../icons/VacationDuotone"
import VacationOutline from "../icons/VacationOutline"
import { REQUESTS } from "../routing/routing"
import { usePermissionsForVisibility } from "../hooks/usePermissionsForVisibility"

interface Props {
  vacationRequestsAvailable: boolean
  username: string
  displayModule?: string
  setDisplayModule: (s: string) => void
}

export default function MobileBottomMenu({
  vacationRequestsAvailable,
  displayModule,
  setDisplayModule,
  username,
}: Props): JSX.Element {
  const { isAllowedToPunchIn, isAllowedToUsePlannerPool } =
    usePermissionsForVisibility({ username })
  return (
    <div className="mobile-bottom-main-menu">
      <AppButton
        displayModule={displayModule}
        setDisplayModule={setDisplayModule}
        appname="dashboard"
      ></AppButton>
      {isAllowedToPunchIn ? (
        <AppButton
          displayModule={displayModule}
          setDisplayModule={setDisplayModule}
          appname="punch"
        ></AppButton>
      ) : null}
      <AppButton
        displayModule={displayModule}
        setDisplayModule={setDisplayModule}
        appname="calendar"
      ></AppButton>
      {isAllowedToUsePlannerPool ? (
        <AppButton
          displayModule={displayModule}
          setDisplayModule={setDisplayModule}
          appname="pool"
        ></AppButton>
      ) : null}
      <AppButton
        displayModule={displayModule}
        setDisplayModule={setDisplayModule}
        appname={REQUESTS}
        disabled={!vacationRequestsAvailable}
      ></AppButton>
    </div>
  )
}

function AppButton({
  displayModule,
  setDisplayModule,
  appname,
  disabled,
}: {
  displayModule?: string
  setDisplayModule: (s: string) => void
  appname: string
  disabled?: boolean
}): JSX.Element | null {
  const intl = useIntl()
  const selected = displayModule === appname
  let Component: any
  const callback = React.useCallback(
    () => setDisplayModule(appname),
    [appname, setDisplayModule]
  )
  if (disabled) {
    return null
  }
  switch (appname) {
    case "punch":
      Component = selected ? ClockCircleOutlined : ClockCircleOutlined
      break
    case "pool":
      Component = selected ? SnippetsOutlined : SnippetsOutlined
      break
    case "calendar":
      Component = selected ? CalendarOutlined : CalendarOutlined
      break
    case REQUESTS:
      Component = selected ? VacationDuotone : VacationOutline
      break
    case "dashboard":
    default:
      Component = selected ? HomeOutlined : HomeOutlined
  }
  return (
    <div className={`main-bottom-menu-btn${selected ? " selected" : ""}`}>
      <Component
        className="icon"
        twotonecolor="#009cc9"
        style={{ color: "#009cc9" }}
        onClick={callback}
      ></Component>
      <div className="title">{intl.formatMessage({ id: appname })}</div>
    </div>
  )
}
