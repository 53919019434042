import * as React from "react"
import type DateContext from "./interfaces/DateContext"
import { Button, Divider } from "antd"
import type { Break } from "../../commonInterfaces/PoolEntry"
import { useIntl } from "react-intl"
import type PlannerData from "../configuration/PlannerData"
import useMobilePoolCallbacks from "./hooks/useMobilePoolCallbacks"
import { timeToString } from "@mina-works/planner-utils"
import type { PoolEntryWithUsageInfo } from "./interfaces/DateContext"
import useMobilePoolReturnAllCallback from "./hooks/useMobilePoolReturnAllCallback"
import ColorBullet from "../../shared/ColorBullet/ColorBullet"
import useMobilePoolReturnShiftCallback from "./hooks/useMobilePoolReturnShiftCallback"
import usePermissions from "./hooks/usePermissions"

const TIMEBASEDPERMISSIONCHECKINTERVAL = 2 * 60 * 1000

interface Props {
  dates: DateContext[]
  departmentId: string
  employeeId: string
  plannerData: PlannerData
  triggerReload: () => void
}

export default function MobilePoolList({
  departmentId,
  employeeId,
  dates,
  plannerData,
  triggerReload,
}: Props): JSX.Element {
  const actualDates = React.useMemo(() => {
    return dates.filter(d => d.entries.length > 0)
  }, [dates])
  return (
    <div className="mobile-pool-list">
      {actualDates.length === 0 ? <NoEntries></NoEntries> : null}
      {actualDates.map(d => (
        <MobilePoolListDate
          triggerReload={triggerReload}
          key={d.date}
          plannerData={plannerData}
          departmentId={departmentId}
          employeeId={employeeId}
          dateContext={d}
        ></MobilePoolListDate>
      ))}
      <div className="mobile-pool-list-padding-for-phone">&nbsp;</div>
    </div>
  )
}

function NoEntries(): JSX.Element {
  const intl = useIntl()
  return (
    <div className="no-entries">
      {intl.formatMessage({ id: "mobile.pool.list.no-pool-entries" })}
    </div>
  )
}

function MobilePoolListDate({
  dateContext,
  departmentId,
  employeeId,
  plannerData,
  triggerReload,
}: {
  dateContext: DateContext
  departmentId: string
  employeeId: string
  plannerData: PlannerData
  triggerReload: () => void
}): JSX.Element {
  const intl = useIntl()
  const entries = React.useMemo(
    () => dateContext.entries.filter(e => e.status !== "removed"),
    [dateContext.entries]
  )
  const { ignore, checkJustCreated, checkDateInAccessibleRange } =
    usePermissions({
      employeeId,
    })
  const canReturnEntry = React.useCallback(
    (e?: PoolEntryWithUsageInfo) => {
      if (e && ignore) {
        return true
      } else if (e) {
        const lastChangeTimestamp = e.lastMessage?.time
        const justCreated =
          lastChangeTimestamp && checkJustCreated(lastChangeTimestamp)
        const sameEmployee = employeeId === e.assigned?.targetEmployeeId
        const dateInAccessibleRange = checkDateInAccessibleRange(e.date)
        return (sameEmployee && justCreated) || dateInAccessibleRange
      } else {
        return false
      }
    },
    [checkDateInAccessibleRange, checkJustCreated, employeeId, ignore]
  )
  const [canBeReturned, setCanBeReturned] = React.useState(
    canReturnEntry(entries.find(e => e.claimedByMe))
  )
  React.useEffect(() => {
    const fn = () => {
      setCanBeReturned(canReturnEntry(entries.find(e => e.claimedByMe)))
    }
    fn()
    const i = setInterval(fn, TIMEBASEDPERMISSIONCHECKINTERVAL)
    return () => clearInterval(i)
  }, [canReturnEntry, entries])
  const { returnToPool } = useMobilePoolReturnAllCallback({
    employeeId,
    departmentId,
    plannerData,
    date: dateContext.date,
    triggerReload,
  })
  const [blocked, setBlocked] = React.useState(false)
  return (
    <div className="mobile-pool-list-date">
      <div className="mobile-pool-date-h">
        <div className="mobile-pool-date-header-text">
          {dateContext.dateForDisplay}
          <Divider type="vertical"></Divider>
          <span>{dateContext.relativeDateForDisplay}</span>
        </div>
        {canBeReturned ? (
          <ReturnShiftsBtn
            num={entries.length}
            returnToPool={returnToPool}
          ></ReturnShiftsBtn>
        ) : (
          <HowManyShifts num={entries.length}></HowManyShifts>
        )}
      </div>
      <div className="mobile-pool-date-list">
        {entries.length === 0
          ? intl.formatMessage({ id: "noentries" })
          : entries.map((e, idx) => (
              <MobilePoolListEntry
                canReturnEntry={canReturnEntry}
                triggerReload={triggerReload}
                key={`${idx}-${e.identifier}`}
                plannerData={plannerData}
                departmentId={departmentId}
                employeeId={employeeId}
                entry={e}
                blocked={blocked}
                block={setBlocked}
              ></MobilePoolListEntry>
            ))}
      </div>
    </div>
  )
}

function HowManyShifts({ num }: { num: number }): JSX.Element {
  const intl = useIntl()
  return (
    <div className="mobile-pool-shifts-per-date">
      {num === 1
        ? intl.formatMessage({ id: "mobile.pool.details.one-shift" })
        : intl.formatMessage({ id: "mobile.pool.details.n-shifts" }, { num })}
    </div>
  )
}

function ReturnShiftsBtn({
  num,
  returnToPool,
}: {
  num: number
  returnToPool: () => void
}): JSX.Element {
  const intl = useIntl()
  return (
    <div className="mobile-pool-shifts-per-date">
      <Button
        className="mobile-pool-return-btn"
        type="link"
        onClick={returnToPool}
      >
        {num === 1
          ? intl.formatMessage({ id: "mobile.pool.details.return-one-shift" })
          : intl.formatMessage(
              { id: "mobile.pool.details.return-n-shifts" },
              { num }
            )}
      </Button>
    </div>
  )
}

function MobilePoolListEntry({
  entry,
  departmentId,
  employeeId,
  plannerData,
  triggerReload,
  block,
  blocked,
  canReturnEntry,
}: {
  canReturnEntry: (e: PoolEntryWithUsageInfo) => boolean
  entry: PoolEntryWithUsageInfo
  departmentId: string
  employeeId: string
  plannerData: PlannerData
  triggerReload: () => void
  block: (b: boolean) => void
  blocked: boolean
}): JSX.Element | null {
  const intl = useIntl()
  const { save } = useMobilePoolCallbacks({
    selectedEntry: entry,
    departmentId,
    employeeId,
    plannerData,
    triggerReload,
    block,
    blocked,
  })
  const isSuperseded = !!entry?.origin?.supersededBy
  const [detailsOpen, setDetailsOpen] = React.useState(false)
  const toggleDetails = React.useCallback(() => setDetailsOpen(d => !d), [])
  const { returnToPool } = useMobilePoolReturnShiftCallback({
    identifier: entry.identifier,
    date: entry.date,
    plannerData,
    triggerReload,
  })
  const [returnDisabled, setReturnDisabled] = React.useState(
    blocked || !canReturnEntry(entry)
  )
  React.useEffect(() => {
    const fn = () => {
      setReturnDisabled(blocked || !canReturnEntry(entry))
    }
    fn()
    const i = setInterval(fn, TIMEBASEDPERMISSIONCHECKINTERVAL)
    return () => clearInterval(i)
  }, [blocked, canReturnEntry, entry])
  const saveCallback = React.useCallback(() => {
    if (
      entry.startTime &&
      entry.endTime &&
      window.confirm(
        intl.formatMessage(
          { id: "pool-mobile-save-confirm" },
          {
            start: timeToString(entry.startTime),
            end: timeToString(entry.endTime),
          }
        )
      )
    ) {
      save()
    }
  }, [intl, entry.startTime, entry.endTime, save])
  const className = React.useMemo(
    () =>
      `mobile-pool-list-entry${
        entry.claimedByMe ? " mobile-pool-list-entry-claimed-by-me" : ""
      }`,
    [entry.claimedByMe]
  )
  return entry.breaks !== undefined &&
    entry.startTime !== undefined &&
    entry.endTime !== undefined ? (
    <div className={`${className}`}>
      <div className="mple-color-bullet">
        {entry.calendarEntryType?.color ? (
          <ColorBullet color={entry.calendarEntryType?.color}></ColorBullet>
        ) : null}
      </div>
      <div className="mple-inner">
        <div className="mple-inner-cet">{entry.calendarEntryType?.name}</div>
        <div className="mple-inner-time">
          <TimeView time={entry.startTime}></TimeView>-
          <TimeView time={entry.endTime}></TimeView>
        </div>
        {entry.breaks.length > 0 ||
        entry.comment ||
        isSuperseded ||
        entry.origin?.employee ? (
          <Button
            className="mobile-pool-details-btn"
            type="link"
            onClick={toggleDetails}
          >
            {detailsOpen
              ? intl.formatMessage({ id: "mobile.pool.details.hide-details" })
              : intl.formatMessage({ id: "mobile.pool.details.show-details" })}
          </Button>
        ) : null}
        {detailsOpen ? (
          <div className="mobile-pool-entry-details">
            <div className="mple-pool-breaks">
              {entry.breaks.map((b, idx) => (
                <MobilePoolListEntryBreak
                  brk={b}
                  key={`${idx}`}
                ></MobilePoolListEntryBreak>
              ))}
            </div>
            {entry.comment ? (
              <div className="mobile-pool-comment">{entry.comment}</div>
            ) : null}
            {isSuperseded ? (
              <div className="mobile-pool-origin-superseding-cet">
                {entry.origin?.employee
                  ? intl.formatMessage(
                      {
                        id: "pool-entry-superseded-employee-is-unavailable-for",
                      },
                      { name: entry.origin.employee.name }
                    )
                  : intl.formatMessage({
                      id: "pool-entry-superseded-employee-is-unavailable",
                    })}
              </div>
            ) : entry.origin?.employee ? (
              <div className="mobile-pool-origin-info-employee">
                {intl.formatMessage(
                  { id: "pool-entry-info-based-on-employee" },
                  { name: entry.origin.employee.name }
                )}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      {entry.claimedByMe ? (
        <div className="mobile-pool-list-entry-cb">
          <Button
            className="mple-inner-btn"
            type="primary"
            disabled={returnDisabled}
            onClick={returnToPool}
          >
            {intl.formatMessage({ id: "pool-mobile-return-btn" })}
          </Button>
        </div>
      ) : (
        <div className="mobile-pool-list-entry-cb">
          <Button
            className="mple-inner-btn"
            type="primary"
            disabled={blocked || entry.status !== "available"}
            onClick={saveCallback}
          >
            {intl.formatMessage({ id: "pool-mobile-save-btn" })}
          </Button>
        </div>
      )}
    </div>
  ) : null
}

function MobilePoolListEntryBreak({ brk }: { brk: Break }): JSX.Element {
  const intl = useIntl()
  return (
    <div className="mobile-pool-list-entry-break">
      <TimeView time={brk.startTime}></TimeView>-
      <TimeView time={brk.endTime}></TimeView> {brk.durationInMinutes}min{" "}
      {intl.formatMessage({ id: "break" })}
    </div>
  )
}

function TimeView({
  time: { hour, minute },
}: {
  time: {
    hour: number
    minute: number
  }
}): JSX.Element {
  const h = React.useMemo(() => `${hour}`.padStart(2, "0"), [hour])
  const m = React.useMemo(() => `${minute}`.padStart(2, "0"), [minute])
  return (
    <div className="mobile-pool-time">
      {h}:{m}
    </div>
  )
}
