const messages = {
  "orgchart.keyFiguresTitle": "Locations",
  "orgchart.node.show": "Show",
  "orgchart.heading": "Organisational Chart",
  "orgchart.showarchived": "Show archived units",
  "orgchart.addChildButton": "Add child unit",
  "orgchart.addSiblingButton": "Add sibling unit",
  "orgchart.archived": "(archived)",
  "orgUnit.newUnitHeader": "New organisational unit",
  "orgchart.backButton": "Back",
  "orgchart.prevButton": "Previous unit",
  "orgchart.nextButton": "Next unit",
  "orgUnit.basics": "Basic information",
  "orgUnit.employees": "Employees",
  "orgUnit.qualifications": "Qualifications",
  "orgUnit.workschedule": "Work schedule",
  "orgUnit.permissions": "Permissions",
  "orgUnit.authorisation": "Authorisation",
  "orgUnit.config": "Configuration",
  "orgUnit.history": "History",
  "orgUnit.update": "changed",
  "orgUnit.create": "created",
  "orgUnit.archive": "archived",
  "orgUnit.restore": "restored",
  "orgUnit.undefined": "",
  "orgUnit.restoreButton": "Restore",
  "orgUnit.archiveButton": "Archive",
  "orgUnit.deleteButton": "Delete",
  "orgUnit.confirmDeleteHeader": "Confirm delete",
  "orgUnit.confirmDeleteText":
    "Do you really want to delete this organisational unit and all archived sub-units?",
  "orgUnit.cancelButton": "Cancel",
  "orgUnit.who": "User",
  "orgUnit.what": "Change",
  "orgUnit.when": "Date",
  "orgUnit.locationCount": "Count locations",
  "orgUnitDetailsForm.name": "Name",
  "orgUnitDetailsForm.abbrev": "Abbreviation",
  "orgUnitDetailsForm.importKey": "Importkey",
  "orgUnitDetailsForm.type": "Type of the organisational unit",
  "orgUnitDetailsForm.color": "Color",
  "orgUnitDetailsForm.showInPlanner": "Show in planer?",
  "orgUnitDetailsForm.isLocation": "Is a location",
  "orgUnitDetailsForm.accounts": "Accounts",
  "orgUnitDetailsForm.country": "Country",
  "orgUnitDetailsForm.state": "Region",
  "orgUnitDetailsForm.zoneName": "Timezone",
  "orgUnitDetailsForm.locationEmail": "Email",
  "orgUnitDetailsForm.saveAndSibling": "new sibling",
  "orgUnitDetailsForm.saveAndChild": "new child",
  "orgUnitDetailsForm.noData": "No data available",
  "orgUnitDetailsForm.error": "Error in form",
  "orgUnitDetailsForm.error.name.required": "A name is required",
  "orgUnitDetailsForm.error.abbrev.required": "An abbreviation is required",
  "orgUnitDetailsForm.error.email": "Invalid email.",
  "orgUnitDetailsForm.error.abbrev.length": "Abbrev. is too long.",
  "orgUnitDetailsForm.error.zoneName": "Timezone is missing.",
  "orgUnitDetailsForm.error.country": "Counry is missing.",
  "orgUnitDetailsForm.error.state": "Sate is missing.",
  "orgUnitDetailsForm.error.isLocationDisabledOrOverQuota":
    "Adding locations is disabled or you've reached the allowed maximum of locations. Please remove the location checkbox before saving.",
  "orgUnitDetailsForm.openinghours": "Opening hours",
  "orgunitDetailsForm.qualifications": "Attendance check",
  "orgunitDetailsForm.qualifications.placeholder": "Select qualification(s)",
  "locationInfo.title": "Parent location information",
  organisation: "Client",
  country: "Country",
  region: "Region",
  district: "District",
  company: "Company",
  branch: "Branch",
  department: "Department",
  subdepartment: "Subdepartment",
  DE: "Germany",
  "DE@BW": "Baden-Württemberg",
  "DE@BY": "Bavaria (Mariä Himmelfahrt)",
  "DE@BY_s": "Bavaria (Mariä Himmelfahrt and Friedensfest)",
  "DE@BY_e": "Bavaria",
  "DE@B": "Berlin",
  "DE@BB": "Brandenburg",
  "DE@HB": "Bremen",
  "DE@HH": "Hamburg",
  "DE@HE": "Hesse",
  "DE@NDS": "Lower Saxony",
  "DE@MV": "Mecklenburg-West Pommerania",
  "DE@NRW": "North Rhine Westphalia",
  "DE@RP": "Rhineland-Palatinate",
  "DE@SL": "Saarland",
  "DE@SN": "Saxony (Corpus Chrisit)",
  "DE@SN_e": "Saxony",
  "DE@SA": "Saxony-Anhalt",
  "DE@SH": "Schleswig-Holstein",
  "DE@TH": "Thuringia (Corpus Chrisit)",
  "DE@TH_e": "Thuringia",
  AT: "Austria",
  "AT@BGLD": "Burgenland",
  "AT@KTN": "Carinthia",
  "AT@NÖ": "Lower Austria",
  "AT@OÖ": "Upper Austria",
  "AT@SBG": "Salzburg",
  "AT@STMK": "Styria",
  "AT@T": "Tyrol",
  "AT@VBG": "Vorarlberg",
  "AT@W": "Vienna",
  CH: "Switzerland",

  "CH@AG": "Aargau",
  "CH@AG_AA_AA": "Aarau",
  "CH@AG_LB_LB": "Laufenburg",
  "CH@AG_RH_ST": "Rheinfelden - Stein (AG)",
  "CH@SZ": "Schwyz",
  "CH@BL": "Basel-Landschaft",
  "CH@BS": "Basel-Stadt",
  "CH@FR": "Freiburg",
  "CH@FR_RF": "Freiburg (reformed)",
  "CH@ZH_w": "Zürich-Winterthur",
  "CH@SO": "Solothurn",
  "CH@SO_hdorf": "Solothurn-Haegedorf",
  "CH@BE": "Bern (Berchtoldstag)",
  "CH@LU": "Luzern",
  "CH@LU_p": "Luzern (Easter Monday and Pentecost)",
  "CH@SH": "Schaffhausen",
  "CH@ZH_b": "Zürich (Berchtoldstag)",
  "CH@ZH": "Zürich",
  "CH@ZH_US_DU": "Zürich - Uster - Dübendorf",
  "CH@ZG": "Zug",
  "CH@ZG_ZG": "Zug - Zug",
  "CH@SG": "St. Gallen",
  "CH@AG_b": "Aargau - District Baden",
  "CH@AG_rh_f": "Aargau - District Rheinfelden (Corpus Chrisit)",
  "CH@AG_rh": "Aargau - District Rheinfelden",
  "CH@AG_br": "Aargau - District Bremgarten",
  "CH@AG_le": "Aargau - District Lenzburg",
  "CH@TG": "Thurgau",
  "CH@AG_zu": "Aargau - Zurzach",
  "CH@WL_BR_BG_E": "Wallis - Brig - Brig-Glis - + Extension",
  "CH@WL_BR_BG": "Wallis - Brig - Brig-Glis",
  "CH@GL_GL": "Glarus",
  "CH@GB_PL": "Graubünden - Plessur",
  "CH@GB_AL_VO": "Graubünden - Albula - Vaz/Obervaz",
  "CH@GB_SS_IL": "Graubünden - Surselva - Ilanz",
  "CH@SZ_HF_FR": "Schwyz - Höfe - Freienbach",
  "CH@SZ_ES_ES": "Schwyz - Einsiedeln - Einsiedeln",
  LI: "Liechtenstein",
  "LI@LI": "Liechtenstein",
  GLC: "Greece",
  "GLC@GLC": "Greece",
  "GLC@GLC_a": "Alternative",
  "GLC@GLC_d": "Dimitrios",
  "GLC@GLC_bs": "Deliverance +15Martyrs",
  "GLC@GLC_th": "Thessaloniki",
  "GLC@GLC_al": "Alexandroupolis",
  "GLC@GLC_ki": "Kilkis",
  IT: "Italy",
  "IT@IT": "Italy",
  NL: "Netherlands",
  "NL@RO": "Roermond",
  SB: "Serbien",
  "SB@BE": "Belgrad",
  "Europe/Berlin": "Europe/Berlin",
  "Europe/Vienna": "Europe/Vienna",
  "Europe/Zurich": "Europe/Zurich",
  "Europe/Athens": "Europe/Athens",
  "Europe/Rome": "Europe/Rome",
  "Europe/Amsterdam": "Europe/Amsterdam",
  "Europe/Brussels": "Europe/Brussels",
  "Europe/Belgrade": "Europe/Belgrade",
  "Pacific/Auckland": "Pacific/Auckland",
  "Pacific/Niue": "Pacific/Niue",
  "Asia/Jakarta": "Asia/Jakarta",
  "Australia/Eucla": "Australia/Eucla",
  "Asia/Tokyo": "Asia/Tokyo",
}

export default messages
