import { combineReducers } from "redux"
// import { connectRouter } from "connected-react-router"

import types from "../actions/actionTypes"

import ui from "../../ui/redux/uiReducer"
import errorHandler from "../../modules/errorHandler/reducer"
import login from "../../modules/login/redux/reducers/loginReducer"
import orgChart from "../../modules/orgChart/redux/reducers/orgChartReducer"
import clientManager from "../../modules/clientManager/redux/reducers/clientManagerReducer"
import userManager from "../../modules/userManager/redux/reducers/userManagerReducer"
import languagePicker from "../../modules/languagePicker/redux/reducers/languagePickerReducer"
import actionProvider from "../../modules/actionProvider/redux/reducers/actionProviderReducer"
// TODO: Should be handled as in redux-form (reducer as reduxReactTable or
//       similar)
import { reduxReactTable } from "../../shared/vmi-rant-table"
import oucontext from "../../shared/OUContext/redux/reducers/ouContextDropdownReducer"
import groupedUserSelect from "../../shared/ui/GroupedUserSelect/redux/groupedUserSelectReducer"
import authorisation from "../../modules/authorisation/redux/reducers/authorisationReducer"
import calendarentrytypes from "../../modules/calendarentrytypes/redux/reducers/calendarentrytypeReducer"
import confirmPassword from "../../modules/confirmPassword/redux/reducers/confirmPasswordReducer"
import confirmDialog from "../../modules/confirmDialog/redux/reducers/confirmDialogReducer"
import settings from "../../modules/settings/redux/reducers/settingsReducer"
import qualifications from "../../modules/qualifications/redux/reducers/qualificationReducer"
import pbbtemplateManager from "../../modules/pbbtemplateManager/redux/reducers/pbbtemplateManagerReducer"
import globalMessage from "../../modules/globalMessage/redux/reducers/globalMessageReducer"
import permissionCheck from "../../shared/permissionCheck/lib/redux/permissionCheckReducer"
import mainMenu from "../../modules/mainMenu/redux/mainMenuReducer"
// eslint-disable-next-line max-len
import absenceOverview from "../../modules/reports/absenceOverview/redux/reducers/unavailabilityReportsReducer"
import timeSheet from "../../modules/reports/timeSheet/redux/reducers/userReportsReducer"
import timeSheetBonuses from "../../modules/reports/timeSheetBonuses/redux/reducers/bonusReportReducer"
import weeklySchedule from "../../modules/reports/weeklySchedule/redux/reducers/calendarReportsReducer"
import vacationList from "../../modules/reports/vacationList/redux/reducers/leaveListReportReducer"
import vacationSheet from "../../modules/reports/vacationSheet/redux/reducers/leaveAccountReportReducer"
import yearSummary from "../../modules/reports/yearSummary/redux/reducers/yearSummaryReportReducer"
import calculationDetails from "../../modules/reports/calculationDetails/redux/reducers/calculationDetailsReportReducer"
import licenseStats from "../../modules/infoPages/licenseStats/redux/licenseStatsReducer"

/**
 * Because this has been a source of confusion: The key used in combineReducers
 * is the key inside the state that the reducer is used for!
 */
const allReducers = (/* history, */ state, action) =>
  combineReducers({
    // router: connectRouter(history),
    errorHandler,
    ui,
    login,
    clientManager,
    orgChart,
    userManager,
    languagePicker,
    actionProvider,
    oucontext,
    groupedUserSelect,
    authorisation,
    calendarentrytypes,
    confirmPassword,
    confirmDialog,
    reduxReactTable,
    settings,
    qualifications,
    pbbtemplateManager,
    globalMessage,
    permissionCheck,
    mainMenu,
    absenceOverview,
    timeSheet,
    timeSheetBonuses,
    weeklySchedule,
    vacationList,
    vacationSheet,
    yearSummary,
    calculationDetails,
    licenseStats,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  })(state, action)

const rootReducer = () /* history */ => (state, action) => {
  if (action.type === types.RESETSTORE) {
    state = {
      login: state.login,
      oucontext: state.oucontext,
    }
  }
  return allReducers(/* history, */ state, action)
}

export default rootReducer
