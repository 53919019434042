import { Spin } from "antd"
import * as React from "react"
import type { IntlShape } from "react-intl"
import { FormattedMessage, injectIntl } from "react-intl"
import type PlannedDay from "../configuration/PlannedDay"
import PlannerData from "../configuration/PlannerData"
import MobileDashboardCalculatedInfo from "./MobileDashboardCalculatedInfo"
import MobileDashboardDay from "./MobileDashboardDay"

export interface Props {
  intl: IntlShape
  clientId: string
  departmentId: string
  employeeId: string
  changeDisplayedModule: (module: "calendar") => void
}

interface State {
  plannerData?: PlannerData
  selectedIndex?: number
}
class MobileDashboard extends React.Component<Props, State> {
  private selectIndexFunctions: { [k: string]: () => void }

  constructor(props: Props) {
    super(props)
    this.state = {}
    this.selectIndex = this.selectIndex.bind(this)
    this.selectIndexFunctions = {}
    this.changeDisplayedModule = this.changeDisplayedModule.bind(this)
  }

  componentDidMount(): void {
    void this.loadPlannerData({} as Props, {} as State)
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    void this.loadPlannerData(prevProps, prevState)
  }

  render(): JSX.Element {
    if (this.state.plannerData?.hasData()) {
      const days = this.state.plannerData?.getDays() ?? []
      return (
        <div className="mobile-dashboard">
          <div className="mobile-dashboard-days">
            {days.length > 0 ? (
              days
                .filter(d => d.getEntries(false).length > 0)
                .map((d, idx) => (
                  <MobileDashboardDay
                    key={idx}
                    day={d}
                    selectDay={this.selectIndex(idx)}
                    selected={this.getSelectedIndex(days) === idx}
                  ></MobileDashboardDay>
                ))
            ) : (
              <div className="info">
                <FormattedMessage id="NoPlanInNextCoupleOfDays"></FormattedMessage>
              </div>
            )}
          </div>
          <MobileDashboardCalculatedInfo plannerData={this.state.plannerData} />
        </div>
      )
    } else if (this.state.plannerData) {
      return <div></div>
    } else {
      return (
        <div className="mobile-dashboard-still-loading">
          <Spin />
        </div>
      )
    }
  }

  private selectIndex(selectedIndex: number) {
    if (this.selectIndexFunctions[selectedIndex]) {
      return this.selectIndexFunctions[selectedIndex]
    } else {
      const fn = () => this.setState({ selectedIndex })
      this.selectIndexFunctions[selectedIndex] = fn
      return fn
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private loadPlannerData(prevProps: Props, prevState: State): void {
    if (this.props.employeeId !== prevProps.employeeId) {
      this.setState(
        {
          plannerData: undefined,
        },
        () => {
          const plannerData = new PlannerData(
            this.props.clientId,
            this.props.employeeId,
            this.props.intl.locale
          )
          // TODO: Unify this with useLoadPlanner!
          void plannerData.load().then(() => this.setState({ plannerData }))
        }
      )
    }
  }

  private getSelectedIndex(days: PlannedDay[]) {
    const idx = this.state.selectedIndex ?? days.findIndex(d => d.isToday())
    return idx === -1 ? 0 : idx
  }

  private changeDisplayedModule() {
    return this.props.changeDisplayedModule("calendar")
  }
}

const MobileDash = injectIntl(MobileDashboard)
export default MobileDash
