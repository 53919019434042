import types from "./actionTypes"
import { fetchREST } from "../../../../shared/rest.js"
import { loginAsync } from "../../../login/redux/actions/loginActions"
import {
  showGlobalMessage,
  convertBackendError,
} from "../../../globalMessage/controller"
import { closeAddUserDialog } from "./userListingActions"

export function openEditCandidateUserDialog(stateId) {
  return { type: types.OPENEDITCANDIDATEUSERDIALOG, stateId }
}

export function closeEditCandidateUserDialog() {
  return { type: types.CLOSEEDITCANDIDATEUSERDIALOG }
}

export function resendRegistrationMessageAsync(fields) {
  return (dispatch, getState) =>
    fetchREST(
      "/userManager/resendRegistrationMessage",
      { fields },
      { method: "POST", state: getState() }
    )
      .then(res => res.json())
      .then(json => {
        if (json.error) {
          showGlobalMessage(dispatch, convertBackendError(json))
          return Promise.resolve()
        }
        dispatch(closeEditCandidateUserDialog())
        return Promise.resolve()
      })
      .catch(err => {
        console.log(err)
      })
}

function updateCandidateExpiresAt(fields) {
  return { type: types.UPDATEEXPIRESAT, fields }
}

export function refreshExpiryAsync({ candidateId, stateId }) {
  return (dispatch, getState) =>
    fetchREST(
      "/userManager/refreshExpiry",
      { candidateId },
      { method: "POST", state: getState() }
    )
      .then(res => res.json())
      .then(json => {
        if (json.error) {
          showGlobalMessage(dispatch, convertBackendError(json))
          return Promise.resolve()
        }
        dispatch(updateCandidateExpiresAt({ stateId, expiresAt: json }))
        return Promise.resolve()
      })
      .catch(err => {
        console.log(err)
      })
}

function removeCandidateUser(candidateId) {
  return { type: types.REMOVECANDIDATEUSER, candidateId }
}

export function expireCandidateAsync(candidateId, stateId) {
  return (dispatch, getState) =>
    fetchREST(
      "/userManager/expireCandidate",
      { candidateId },
      { method: "POST", state: getState() }
    )
      .then(res => res.json())
      .then(json => {
        if (json.error) {
          showGlobalMessage(dispatch, convertBackendError(json))
          return Promise.resolve()
        }
        dispatch(removeCandidateUser(stateId))
        return Promise.resolve()
      })
      .catch(err => {
        console.log(err)
      })
}

function appendPasswordChangeError(error) {
  return { type: types.APPENDPASSWORDCHANGEERROR, error }
}

function passwordChanged() {
  return { type: types.PASSWORDCHANGED }
}

/**
 * Change the users password inplace (without mail).
 */
export function changePasswordAsync(props) {
  return (dispatch, getState) => {
    return fetchREST(
      "/userManager/changePassword",
      { props },
      { method: "POST", state: getState() }
    )
      .then(res => res.json())
      .then(json => {
        if (json.error) {
          dispatch(appendPasswordChangeError(json))
          return Promise.resolve()
        } else {
          dispatch(passwordChanged())
          // TODO: We still need a generall success message component...
        }
        return Promise.resolve()
      })
      .catch(err => {
        console.log(err)
      })
  }
}

function appendRegistrationError(error) {
  return { type: types.APPENDREGISTRATIONERROR, error }
}

export function createUserFromCandidateAsync(fields) {
  return (dispatch, getState) =>
    fetchREST(
      "/userManager/createUserFromCandidate",
      { fields },
      { method: "POST", state: getState() }
    )
      .then(res => res.json())
      .then(json => {
        if (json.error) {
          dispatch(appendRegistrationError(json))
        } else {
          dispatch(loginAsync(json.username, fields.password, undefined))
        }
        return Promise.resolve(json)
      })
      .catch(err => {
        console.log(err)
      })
}

export function addCandidateUserAsync(fields) {
  return (dispatch, getState) => {
    return fetchREST(
      "/userManager/addCandidateUser",
      { fields },
      { method: "POST", state: getState() }
    )
      .then(res => res.json())
      .then(json => {
        if (!json.error) {
          dispatch(closeAddUserDialog())
        }
      })
  }
}
