import type { ColorJSON } from "./Color"
import Color from "./Color"

export default class EntryClassification {

  constructor(
    private readonly id: string,
    private readonly name: string,
    private readonly abbreviation: string,
    private readonly color?: Color,
    private readonly isActivity: boolean = true,
    private readonly unavailabilitytype?: string
  ) { }

  static fromJSON(json: Record<string, string | ColorJSON | boolean>): EntryClassification {
    return new EntryClassification(
      json.id as string,
      json.name as string,
      json.abbreviation as string,
      Color.fromJSON(json.color as ColorJSON),
      !!json.isActivity,
      json.unavailabilitytype ? json.unavailabilitytype as string : undefined
    )
  }

  getId(): string { return `${this.id}` }
  getName(): string { return this.name }
  getAbbreviation(): string { return this.abbreviation }
  getColor(): Color | undefined { return this.color }

  isSickLeave(): boolean {
    return this.unavailabilitytype === 'sick'
  }
  isVacation(includeSpecial = true): boolean {
    return this.unavailabilitytype === 'vacation' || (
      includeSpecial && this.unavailabilitytype === 'special'
    )
  }
  isOtherUnavailability(): boolean {
    return !this.isActivity && !this.isSickLeave() && !this.isVacation(true)
  }

  appliesToFullDays(): boolean { return !this.isActivity }
  appliesToPartsOfDays(): boolean { return this.isActivity }

  getKey(): string {
    // XXX Color or name changes don't signify a difference!
    return this.getId()
  }

  toJSON(): Record<string, any> {
    const r = {
      id: this.id,
      name: this.name,
      abbreviation: this.abbreviation,
      color: this.color?.toJSON(),
      isActivity: this.isActivity,
      unavailabilitytype: this.unavailabilitytype
    }
    return r
  }

  clone(): EntryClassification {
    return new EntryClassification(
      this.id,
      this.name,
      this.abbreviation,
      this.color?.clone(),
      this.isActivity,
      this.unavailabilitytype
    )
  }

}