export default class Color {

  constructor(
    public readonly r: ColorComponent,
    public readonly g: ColorComponent,
    public readonly b: ColorComponent,
    private a: AlphaChannel | null = null,
  ) { }

  static fromJSON(json?: ColorJSON): Color {
    return new Color(json?.r ?? 0, json?.g ?? 0, json?.b ?? 0, json?.alpha ?? 1)
  }

  setAlpha(alpha: AlphaChannel): void { this.a = alpha }
  getRed(): number { return this.r }
  getGreen(): number { return this.g }
  getBlue(): number { return this.b }
  getAlpha(): number | null { return this.a }

  toJSON(): ColorJSON { return { r: this.r, g: this.g, b: this.b, alpha: this.a } }

  clone(): Color {
    return new Color(this.r, this.g, this.b, this.a)
  }
}

export interface ColorJSON {
  r: number
  g: number
  b: number
  alpha: number | null
}

/**
 * Number from 0..255
 */
type ColorComponent = number

/**
 * Number from 0..1
 */
type AlphaChannel = number