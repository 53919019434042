import * as React from "react"
import MobileProcessManagerForm from "./MobileProcessManagerForm"
import type { OverviewProcess } from "../../commonConfiguration/processes/ProcessList"
import ProcessList from "../../commonConfiguration/processes/ProcessList"
import WebPushNotifications from "../../shared/WebPushNotifications"
import { Button, Spin } from "antd"
import type { Settings } from "../utils/SettingsPanel"
import { ReloadOutlined } from "@ant-design/icons"
import ProcessDetails from "../../shared/processManagerUtils/ProcessDetails"
import { useIntl } from "react-intl"

export interface Props {
  clientId: string
  departmentId: string
  employeeId: string
  changeDisplayedModule: (module: string) => void
  locale: string
  notificationCount: number
  saveUserSettings: (settings: Settings) => Promise<void>
  loadUserSettings: () => Promise<Settings>
}

export default function MobileProcessManager(props: Props): JSX.Element {
  const intl = useIntl()
  const webPushNotifications = React.useMemo(
    () => new WebPushNotifications(),
    []
  )
  const [propagateChanges_, setPropagateChanges_] = React.useState(new Date())
  const [currentProcessId, setCurrentProcessId] = React.useState<
    string | undefined
  >()
  const [processes, setProcesses] = React.useState<OverviewProcess[]>([])
  const load = React.useCallback(() => {
    const processList = new ProcessList(
      props.departmentId,
      props.clientId,
      props.employeeId,
      intl.locale
    )
    void processList.loadOverviewList(true).then(() => {
      setProcesses(processList?.getList() ?? [])
    })
  }, [intl.locale, props.clientId, props.departmentId, props.employeeId])
  React.useEffect(() => {
    void webPushNotifications
      .subscribe()
      .catch(e => console.log("Error subscribing to WebPush:", e))
    void load()
  }, [load, webPushNotifications])
  React.useEffect(() => {
    void load()
  }, [
    props.employeeId,
    propagateChanges_,
    props.notificationCount,
    currentProcessId,
    load,
  ])
  const propagateChanges = React.useCallback(() => {
    setCurrentProcessId(undefined)
    setPropagateChanges_(new Date())
  }, [])
  const emptyTransitionList = React.useMemo(() => [], [])
  const currentProcess: OverviewProcess | undefined = React.useMemo(
    () =>
      currentProcessId !== undefined
        ? processes?.find((p: any) => p.getId() === currentProcessId)
        : undefined,
    [currentProcessId, processes]
  )
  const className = ["mobile-process-list-main-details"]
  return (
    <div className="mobile-main-content-area mobile-process-manager">
      <div className="mobile-process-list-h">
        <Button
          size="small"
          className="mobile-pool-reload-button"
          shape="circle"
          type="text"
          icon={<ReloadOutlined></ReloadOutlined>}
          onClick={load}
        ></Button>
        <h1>{intl.formatMessage({ id: "VacationRequests" })}</h1>
      </div>
      <div className="mobile-process-lists-container">
        <div className="mobile-process-list-container">
          {processes && processes.length > 0 ? (
            <ProcessListComponent
              processes={processes}
              currentlyOpen={true}
              currentProcessId={currentProcessId}
              setCurrentProcessId={setCurrentProcessId}
            ></ProcessListComponent>
          ) : processes !== undefined ? (
            intl.formatMessage({ id: "NoVacationRequestsAvailable" })
          ) : (
            <Spin />
          )}
        </div>
        {(processes?.filter((p: any) => !p.isCurrentlyOpen())?.length ?? 0) >
        0 ? (
          <>
            <h4>{intl.formatMessage({ id: "OlderClosedVacationRequests" })}</h4>
            <div className="mobile-process-list-container">
              <ProcessListComponent
                processes={processes}
                currentlyOpen={false}
                currentProcessId={currentProcessId}
                setCurrentProcessId={setCurrentProcessId}
              ></ProcessListComponent>
            </div>
          </>
        ) : null}
      </div>
      <div className={className.join(" ")}>
        {currentProcess ? (
          <ProcessDetails
            processType={currentProcess.getProcessType()}
            showTitle={true}
            classPrefix="mobile-"
            clientId={props.clientId}
            departmentId={props.departmentId}
            employeeId={props.employeeId}
            id={currentProcessId}
          ></ProcessDetails>
        ) : null}
        <MobileProcessManagerForm
          availableTransitions={
            currentProcess !== undefined
              ? currentProcess.getAvailableTransitionTypes()
              : emptyTransitionList
          }
          processType={currentProcess?.getProcessType()}
          propagateChanges={propagateChanges}
          clientId={props.clientId}
          departmentId={props.departmentId}
          employeeId={props.employeeId}
          changeDisplayedModule={props.changeDisplayedModule}
          locale={intl.locale}
          id={
            /* TODO: use this.state.currentProcess instead! */
            currentProcess !== undefined ? currentProcess.getId() : undefined
          }
        ></MobileProcessManagerForm>
      </div>
    </div>
  )
}

function ProcessListComponent({
  processes,
  currentlyOpen,
  currentProcessId,
  setCurrentProcessId,
}: {
  processes: OverviewProcess[]
  currentlyOpen: boolean
  currentProcessId: string | undefined
  setCurrentProcessId: (id?: string) => void
}): JSX.Element {
  const intl = useIntl()
  currentlyOpen = currentlyOpen ?? false
  const toLocaleDateString = (d: any) =>
    d?.toLocaleDateString(intl.locale, {
      weekday: "long",
      day: "numeric",
      month: "long",
    })
  const localDate = (d: any, withPreposition = true) => {
    let s = ""
    const dString = toLocaleDateString(d)
    const now = new Date()
    const yesterday = new Date(now)
    yesterday.setDate(yesterday.getDate() - 1)
    if (dString === toLocaleDateString(now)) {
      s = intl.formatMessage({ id: "today" })
    } else if (dString === toLocaleDateString(yesterday)) {
      s = intl.formatMessage({ id: "yesterday" })
    } else {
      if (withPreposition) {
        s = `${intl.formatMessage({ id: "date-on" })} ${dString}`
      } else {
        s = dString
      }
    }
    return s
  }
  return (
    <>
      {processes
        ?.filter((p: any) =>
          currentlyOpen ? p.isCurrentlyOpen() : !p.isCurrentlyOpen()
        )
        .map((p: OverviewProcess, idx: number) => {
          const cls = [
            "mobile-process-list-entry",
            "mobile-process-list-entry-type-" +
              p.getLastTransactionVisualisationType(),
          ]
          if (p.isEffectivelyUnread()) {
            cls.push("mobile-process-list-action-required")
          }
          if (currentProcessId === p.getId()) {
            cls.push("mobile-process-list-active")
          }
          return (
            <div
              className={cls.join(" ")}
              key={idx}
              onClick={() =>
                setCurrentProcessId(
                  currentProcessId === p.getId() ? undefined : p.getId()
                )
              }
            >
              <div className="mobile-process-list-entry-identification">
                <div className="mobile-process-list-entry-digest">
                  {intl.formatMessage({
                    id: `full-${p.getProcessType()}`,
                  })}
                  : {p.getHACKYShortenedDigest()}
                </div>
              </div>
              <div className="mobile-process-list-entry-status">
                {!p.isNew() ? (
                  <>
                    <div className="mobile-process-list-entry-last-transaction">
                      {p.getLastTransactionLabel()}
                    </div>
                    <div className="mobile-process-list-entry-last-changed">
                      {localDate(p.getLastChanged())}
                    </div>
                  </>
                ) : (
                  <div className="mobile-process-list-entry-new">
                    {intl.formatMessage({ id: "NoReplyYet" })}
                  </div>
                )}
              </div>
            </div>
          )
        })}
    </>
  )
}
