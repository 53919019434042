import { DeleteOutlined } from "@ant-design/icons"
import { Button, Select } from "antd"
import * as React from "react"
import type EditableLoggedEntry from "./EditableLoggedEntry"
import type {
  EntryClassificationV3,
  OrganisationalUnitV3,
} from "../../commonInterfaces/PlannerV3"
import { Break } from "../configuration/PlannedDay"
import type { IntlShape } from "react-intl"
import { injectIntl } from "react-intl"
import { findDepartment } from "../utils/findDepartment"
import TimeWidget from "../../shared/TimeWidget/TimeWidget"
import PlannerTime from "../../shared/plannerDateTime/PlannerTime"
import EntryDepartmentWidget from "../../PlannerApp/Planner/lib/detailsAndForm/EntryDepartmentWidget"
import OrganisationalUnit from "../../PlannerApp/Planner/models/OrganisationalUnit"
import ColorBullet from "../../shared/ColorBullet/ColorBullet"
import MobileMultiBreakFormContainer from "./MobileMultiBreakFormContainer"

export interface EntryFormProps {
  intl: IntlShape
  ouId: string
  entry: EditableLoggedEntry
  loading: boolean
  idx: number
  removeEntry: (idx: number) => void
  startDate?: string
  organisationalUnits: OrganisationalUnitV3[]
  entryUpdatedCallback: () => void
}

// TODO: move state.entry -> state.breaks
// TODO: modify breaks directly, update state, update entry prop
export interface EntryFormState {
  // entry?: Entry
  // Things that aren't self-tracking widgets need their state tracked:
  breaks?: Break[]
  classification?: EntryClassificationV3
  organisationalUnit?: OrganisationalUnitV3
  entryClassifications?: EntryClassificationV3[]
}

class MobileEntryForm extends React.Component<EntryFormProps, EntryFormState> {
  constructor(props: EntryFormProps) {
    super(props)
    this.state = {}
    this.changeStartTime = this.changeStartTime.bind(this)
    this.changeEndTime = this.changeEndTime.bind(this)
    this.changeClassification = this.changeClassification.bind(this)
    this.changeOrganisationalUnit = this.changeOrganisationalUnit.bind(this)
    this.addBreak = this.addBreak.bind(this)
    this.removeBreak = this.removeBreak.bind(this)
    this.removeEntry = this.removeEntry.bind(this)
    this.handleEntryUpdate = this.handleEntryUpdate.bind(this)
  }

  componentDidMount(): void {
    this.handleEntryUpdate()
  }

  componentDidUpdate(oldProps: EntryFormProps): void {
    if (
      oldProps.loading !== this.props.loading ||
      oldProps.entry !== this.props.entry ||
      // This can now be changed from outside:
      this.state.classification?.id !==
        this.props.entry?.getClassification()?.id
    ) {
      this.handleEntryUpdate()
    }
  }

  render(): JSX.Element | null {
    const e = this.props.entry
    if (!e) {
      return null
    } else {
      return this.renderEntryTimespanDetails(e)
    }
  }

  private handleEntryUpdate() {
    const deptId: undefined | string = this.props.entry?.getDepartmentId()
    const organisationalUnitForEmployee = findDepartment(
      `${this.props.ouId}`,
      this.props.organisationalUnits
    )
    const organisationalUnitForEntry =
      deptId !== undefined
        ? findDepartment(deptId, this.props.organisationalUnits)
        : undefined
    this.setState(
      {
        breaks: this.props.entry.getBreaks(), // TODO: Clone each?
        classification: this.props.entry?.getClassification(),
        organisationalUnit: organisationalUnitForEntry,
        entryClassifications:
          findDepartment(
            this.getOUId(organisationalUnitForEmployee),
            this.props.organisationalUnits ?? []
          )?.availableClassifications ?? [],
      },
      () => this.props.entryUpdatedCallback()
    )
  }

  private getOUId(ou?: OrganisationalUnitV3): string | undefined {
    return ou?.id ?? this.props.ouId
  }

  private addBreak() {
    this.props.entry.addBreak(new Break({ durationInMinutes: 0 }))
    this.setState(
      { breaks: this.props.entry.getBreaks()?.map(b => b) ?? [] },
      this.props.entryUpdatedCallback
    )
  }

  private removeBreak(idx: number) {
    this.props.entry.removeBreak(idx)
    this.setState(
      { breaks: this.props.entry.getBreaks()?.map(b => b) ?? [] },
      this.props.entryUpdatedCallback
    )
  }

  // TODO: At least everything below must bubble up and affect swimlanes!
  private removeEntry() {
    this.props.removeEntry(this.props.idx) // TODO: Promise!
    this.props.entryUpdatedCallback()
  }

  private changeStartTime(t: PlannerTime) {
    // NOTE: Not using setState, widget has its own state!
    this.props.entry.setStartTime(t.toString())
    this.props.entryUpdatedCallback()
  }

  private changeEndTime(t: PlannerTime) {
    // NOTE: Not using setState, widget has its own state!
    this.props.entry.setEndTime(t.toString())
    this.props.entryUpdatedCallback()
  }

  private changeClassification(id: string) {
    const classification = this.state.entryClassifications?.find(
      c => `${c.id}` === `${id}`
    )
    this.props.entry.setClassification(classification)
    this.setState({ classification }, () => this.props.entryUpdatedCallback())
  }

  private changeOrganisationalUnit(id?: string) {
    // const id: string = param.key
    const dept = findDepartment(id, this.props.organisationalUnits)
    this.props.entry.setDepartment(dept)
    this.setState({ organisationalUnit: dept }, () =>
      this.props.entryUpdatedCallback()
    )
  }

  private renderEntryTimespanDetails(e: EditableLoggedEntry) {
    const startTime = this.getStartTime()
    const endTime = this.getEndTime()
    return (
      <div className="mce-entry-form">
        <div className="mce-entry-form-head">
          <div className="mce-entry-times">
            <TimeWidget
              fixLeftAlignment={true}
              allowUndefined={true}
              value={startTime ? PlannerTime.fromJSON(startTime) : undefined}
              onChange={this.changeStartTime}
            ></TimeWidget>
            <span className="entry-entry-separator">&ndash;</span>
            <TimeWidget
              getPopupContainer={undefined}
              allowUndefined={true}
              value={endTime ? PlannerTime.fromJSON(endTime) : undefined}
              onChange={this.changeEndTime}
            ></TimeWidget>
            <div className="mce-remove-entry">
              <Button
                danger
                title={this.t("remove-entry")}
                type="link"
                onClick={this.removeEntry}
                size="small"
              >
                <DeleteOutlined />
              </Button>
            </div>
          </div>
          <div className="mce-entry-select">
            {this.renderEntryClassification()}
          </div>
          <div className="mce-entry-select">
            {this.renderEntryOrganisationalUnit(e)}
          </div>
        </div>
        {this.renderEntryBreaks()}
      </div>
    )
  }

  private t(id: string, v: { [k: string]: string } = {}): string {
    return `${this.props.intl.formatMessage({ id }, v)}`
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private renderEntryOrganisationalUnit(e: EditableLoggedEntry) {
    const selectedId = this.state.organisationalUnit?.id
    return (
      <div className="mce-ou-list">
        <EntryDepartmentWidget
          roots={
            this.props.organisationalUnits?.map(o =>
              OrganisationalUnit.fromJSON(o)
            ) ?? []
          }
          onSelect={this.changeOrganisationalUnit}
          selectedId={selectedId}
        />
      </div>
    )
  }

  private renderEntryClassification() {
    const selectedId = this.state.classification?.id
    const applicableClassifications =
      this.state.entryClassifications?.filter(c => c.isActivity) ?? []
    const selectedValue = applicableClassifications.find(
      c => c.id === selectedId
    )?.id
    return (
      <div className="mce-classification-list">
        <Select
          dropdownStyle={{ maxWidth: "calc(100vw - 40px)" }}
          dropdownMatchSelectWidth={false}
          allowClear={false}
          placeholder={this.props.intl.formatMessage({
            id: "nothing selected",
          })}
          onChange={this.changeClassification}
          value={selectedValue}
        >
          {applicableClassifications
            ?.filter(c => c.availabletoemployees)
            ?.map(c => {
              const value = `${c.id}`
              const t = (
                <div style={{ display: "inline-block" }}>
                  <ColorBullet color={c.color}></ColorBullet>
                  {`${c.name}`}
                </div>
              )
              return (
                <Select.Option value={value} key={value}>
                  {t}
                </Select.Option>
              )
            })}
        </Select>
      </div>
    )
  }

  private renderEntryBreaks() {
    return (
      <MobileMultiBreakFormContainer
        entry={this.props.entry}
        startDate={this.props.startDate!}
        breaks={this.state.breaks ?? []}
        addBreak={this.addBreak}
        removeBreak={this.removeBreak}
        entryUpdateCallback={this.props.entryUpdatedCallback}
      ></MobileMultiBreakFormContainer>
    )
  }

  private getStartTime() {
    return this.props.entry.getStartTime() ?? undefined // "00:00"
  }

  private getEndTime() {
    return this.props.entry.getEndTime() ?? undefined // "00:00"
  }
}

const MobileEntryFormWithIntl = injectIntl(MobileEntryForm)
export default MobileEntryFormWithIntl
