/* Container component for password reset form */

import React, { useRef } from "react"
import { connect } from "react-redux"
import { injectIntl } from "react-intl"
import { Modal, Button, Alert, Form, Input } from "antd"
import { UserOutlined } from "@ant-design/icons"

import {
  getOpenPasswordResetDialogState,
  getInitialResetUsernameState,
  getPasswordResetErrorState,
} from "../redux/selectors/loginSelectors"
import {
  resetPasswordAsync,
  closePasswordResetDialog,
} from "../redux/actions/loginActions"
import withRouter from "../../../shared/withRouter"

const mapStateToProps = state => ({
  openPasswordResetDialog: getOpenPasswordResetDialogState(state),
  initialResetUsername: getInitialResetUsernameState(state),
  error: getPasswordResetErrorState(state),
})

const mapDispatchToProps = (dispatch, { navigate }) => {
  return {
    dispatchClosePasswordResetDialog: () =>
      dispatch(closePasswordResetDialog()),
    dispatchResetPasswordAsync: username => {
      dispatch(resetPasswordAsync(username, undefined, navigate))
    },
  }
}

const PasswordResetModalContainer = props => {
  const inputRef = useRef()
  const translate = (msg, values) => props.intl.formatMessage(msg, values)
  const onSubmit = values => {
    props.dispatchResetPasswordAsync((values.username ?? "").trim())
  }
  const onClose = () => {
    props.dispatchClosePasswordResetDialog()
  }
  return (
    <Modal
      title={translate({ id: "passwordReset" })}
      onCancel={onClose}
      mask={true}
      maskClosable={false}
      open={props.openPasswordResetDialog}
      footer={null}
      className="resetModal"
      afterOpenChange={open => open && inputRef.current?.focus()}
    >
      {props.error.error && (
        <Alert
          message={translate({ id: "A problem with the username occurred" })}
          description={translate(
            {
              id: props.error.type,
              defaultMessage: props.error.errorMessage,
            },
            { username: props.error.username }
          )}
          type="error"
        />
      )}
      {translate({ id: "passwordResetLockNote" })}
      <br />
      <br />
      <Form
        onFinish={onSubmit}
        initialValues={{ username: (props.initialResetUsername ?? "").trim() }}
      >
        <Form.Item
          rules={[
            {
              required: true,
              message: translate({ id: "usernameMissing" }),
            },
          ]}
          name="username"
          label={translate({ id: "username" })}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder={translate({ id: "username" })}
            ref={inputRef}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {translate({ id: "send" })}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(PasswordResetModalContainer)))
