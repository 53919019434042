/**
 * Please note:
 * At this point in time, the backend calls
 * - processes workflows
 * - transitions transactions
 * - transition types transitions or transition types (i.e., in the backend
 * code, a transition type is the type of a transaction)
 *
 * There are two types of views into a process:
 * - The overview list (IWorkflowInstanceOverview[])
 * - The actual process (IWorkflow)
 */

import type { IAvailableTransitionType } from "../../commonInterfaces/processes/IAvailableTransitionType"
import type {
  IWorkflowInstanceOverview,
  IWorkflowInstanceOverviewContainer,
} from "../../commonInterfaces/processes/RawProcessList"
import { ProcessActor } from "./ProcessActor"

const ALLURL = "/rest/workflows/getAllWorkflowInstances"
const MYURL = "/rest/workflows/getMyWorkflowInstances"

export default class ProcessList {
  private rawProcessList?: IWorkflowInstanceOverview[]
  private rawContainer?: IWorkflowInstanceOverviewContainer

  constructor(
    private departmentId: string,
    private clientId: string,
    private employeeId: string,
    private locale: string,
    private showAll?: boolean
  ) { }

  public async loadManagerBadgeCount(): Promise<number> {
    const baseURL = '/rest/workflows/getNumActionRequiredForPV'
    const params: { [k: string]: any } = {
      orgunitId: this.departmentId,
      contextOrgunitId: this.departmentId,
      contextClientId: this.clientId,
    }
    if (this.showAll) {
      params.showAll = true
    }
    const search = new URLSearchParams(params).toString()
    const url = `${baseURL}?${search}`
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    // Type: undefined or error or IWorkflowInstanceOverviewContainer...
    const json = await res.json()
    return json
  }

  public async loadOverviewList(justMine = false): Promise<void> {
    const baseURL = justMine ? MYURL : ALLURL
    const params: { [k: string]: any } = {
      orgunitId: this.departmentId,
      contextOrgunitId: this.departmentId,
      contextClientId: this.clientId,
      locale: this.locale,
    }
    if (this.showAll) {
      params.showAll = true
    }
    const search = new URLSearchParams(params).toString()
    const url = `${baseURL}?${search}`
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    // Type: undefined or error or IWorkflowInstanceOverviewContainer...
    const json = await res.json()
    if (
      !json?.workflowInstances ||
      (json?.feedback?.statusCode && json?.feedback?.statusCode !== "ok")
    ) {
      alert(
        `Ein unerwarteter Fehler ist aufgetreten: ${json?.feedback?.description ?? "-"
        }`
      )
      this.rawProcessList = []
    } else {
      this.rawContainer = json
      this.rawProcessList = this.rawContainer?.workflowInstances
    }
  }

  public getAdditionalFields(): any[] | undefined {
    return this.rawContainer?.additionalFields
  }

  public getList(): OverviewProcess[] {
    if (this.rawProcessList) {
      const seen: string[] = []
      return this.rawProcessList
        .filter(wio => {
          let keep = !!wio
          if (seen.includes(wio.workflowInstanceId)) {
            console.warn("DUPLICATE ENTRY IN PROCESS LIST:", wio.workflowInstanceId)
            keep = false
          }
          seen.push(wio.workflowInstanceId)
          return keep
        })
        .map(makeOverviewProcess)
    } else {
      return []
    }
  }
}

function makeOverviewProcess(w: IWorkflowInstanceOverview) {
  return new OverviewProcess(w)
}

export class OverviewProcess {
  private createdAt: Date
  private lastChanged: Date
  private resolvedDate: Date
  private initiator: ProcessActor
  private lastActor: ProcessActor

  constructor(private readonly rawProcess: IWorkflowInstanceOverview) {
    this.createdAt = new Date(this.rawProcess.createdAt)
    this.lastChanged = new Date(this.rawProcess.lastTransaction.createdAt)
    this.resolvedDate = new Date(this.rawProcess.resolvedDate)
    this.initiator = new ProcessActor(this.rawProcess.initiator)
    this.lastActor = new ProcessActor(this.rawProcess.lastTransaction.actor)
  }

  getProcessType(): string {
    return this.rawProcess.workflowType
  }

  getAdditionalFieldValue(key: string): any {
    const fields = this.rawProcess.additionalFields
    if (fields !== undefined) {
      return fields[key]
    }
  }

  isNew(): boolean {
    return this.getLastTransactionName() === "issue"
  }

  isEffectivelyUnread(): boolean {
    return !!this.rawProcess.isNotifiable
  }

  actionIsRequired(): boolean {
    return !!this.rawProcess.actionRequired
  }

  isArchived(): boolean {
    return !!this.rawProcess.isArchived
  }

  isCurrentlyOpen(): boolean {
    return !!this.rawProcess.currentlyOpen
  }

  isAccepted(): boolean {
    return this.getLastTransactionName() === "accept"
  }

  getId(): string {
    return "" + this.rawProcess.workflowInstanceId
  }

  getLastTransactionLabel(): string {
    if (this.isArchived()) {
      return this.rawProcess.resolvedState
    } else {
      return this.rawProcess.lastTransaction?.label ?? ''
    }
  }

  getLastTransactionName(): string {
    return this.rawProcess.lastTransaction.transitionname
  }

  getLastTransactionVisualisationType(): string | undefined {
    return this.rawProcess.lastTransaction.visualisationtype
  }

  getCurrentStateName(): string {
    return this.rawProcess.currentState
  }

  getLastMessage(): string {
    return this.rawProcess.lastMessage
  }

  getDepartmentName(): string {
    return this.lastActor.getDepartmentName()
  }

  getInitiator(): ProcessActor {
    return this.initiator
  }

  getHACKYShortenedDigest(): string {
    return this.rawProcess.digest.replace(/^Details[:] /, '')
  }

  getDigest(): string {
    return this.rawProcess.digest
  }

  getLastActor(): ProcessActor {
    return this.lastActor
  }

  getLastChanged(): Date {
    if (this.isArchived()) {
      return this.resolvedDate
    } else {
      return this.lastChanged
    }
  }

  getCreatedAt(): Date {
    return this.createdAt
  }

  getAvailableTransitionTypes(): IAvailableTransitionType[] {
    // TODO: Needs a wrapper
    return this.rawProcess.availableTransitionTypes
  }
}
