/* eslint-disable max-len */
const messages = {
  "clients.ticketNumbers": "Tickets",
  "clients.masterdata": "Stammdaten",
  "clients.licenses": "Lizenzen",
  "clients.licenseVolume": "Aktuelles Lizenzvolumen",
  "clients.heading": "Mandanten",
  "clients.clientname": "Name des Mandanten",
  "clients.clientnumber": "Kennung",
  "clients.currentKeyFigures": "Aktuelle Kennzahlen",
  "clients.currentKeyFigures.descriptions":
    'Mitarbeiter können gleichzeitig "gesperrt", "versteckt", und "archiviert" sein. Die Summe der zweiten Zeile muss daher nicht identisch zu der Summe der "Aktuell verwendet" Summe sein. Alle "Aktive Benutzer", "Gesperrte", und "Versteckte" Mitarbeiter zählen für die "Aktuell verwendet" Summe. Nur "Archivierte" Mitarbeiter geben Lizenzen wieder frei.',
  "clients.licensedUsers": "Lizenzvolumen",
  "clients.totalUsers": "Gesamt Benutzer",
  "clients.usedUsers": "Verwendete Lizenzen",
  "clients.activeUsers": "Aktive Benutzer",
  "clients.recentlySeenUsers": "Kürzlich angemeldete Benutzer",
  "clients.availableUsers": "Verfügbare",
  "clients.lockedUsers": "Gesperrte",
  "clients.hiddenUsers": "Versteckte",
  "clients.deletedUsers": "Gelöschte Benutzer",
  "clients.archivedUsers": "Archivierte",
  "clients.availableLicenses": "Freies Volumen",
  "clients.stats.licenseVolume": "Lizenzvolumen",
  "clients.stats.currentlyInUse": "Aktuell verwendet",
  "clients.stats.stillRemaining": "Noch verfügbar",
  "clients.contact": "Kontakt",
  "clients.history": "Historie",
  "clients.current": "Aktuell",
  "clients.contactPerson": "Ansprechpartner",
  "clients.contactPrefix": "Titel",
  "clients.contactSalutation": "Anrede",
  "clients.contactFirstname": "Vorname",
  "clients.contactLastname": "Nachname",
  "clients.phone": "Tel.",
  "clients.email": "E-Mail",
  "clients.contactPhone": "Telefonnummer",
  "clients.contactEmail": "E-Mail-Adresse",
  "clients.notes": "Notizen",
  "clients.category": "Kategorie",
  "clients.highlight": "Markieren",
  "clients.ignoreForStatistics": "In Statistiken ignorieren",
  "clients.marked": "Markiert",
  "clients.edit": "Bearbeiten",
  "clients.new": "Neu anlegen",
  "clients.changeddate": "Änderungszeitpunkt",
  "clients.changedby": "Änderung durch...",
  "clients.delete": "Mandant löschen",
  "clients.reallydelete": "Wollen Sie den Mandanten wirklich löschen?",
  "clients.billingExport": "Abrechnung CSV",
  "clients.totalClients": "Mandanten",
  "clients.totalEmployees": "Mitarbeiter",
  "clients.totalLicenses": "Alle Lizenzen",
  "clients.totalLicensesInUse": "Verwendete Lizenzen",
  "no date": "kein Datum angegeben",
  "form.error": "Die Daten konnten nicht gespeichert werden.",
  "client.couldntSave": "Mandant konnte nicht gespeichert werden",
  "client.missingName": "Mandantenname fehlt",
  "client.missingNumber": "Mandantennummer fehlt",
  "clients.perPage": "Seite",
  "license.modalHeader": "Lizenz bearbeiten",
  "license.effectivedate": "Gültigkeitsdatum",
  "license.expirydate": "Ablaufdatum",
  "license.firstbillingdate": "Erste Rechnung",
  "license.numberofusers": "Lizenzvolumen",
  "license.isinvalid": "Ungültig",
  "license.validationErrors": "Fehler bei der Validierung",
  "license.missingEffectivedate": "Gültigkeitsdatum fehlt",
  "license.missingFirstbillingdate": "Ablaufdatum fehlt",
  "license.newLicenseButton": "Neue Lizenz",
  "license.unlimited": "unbegrenzt",
  "license.expirydateBeforeEffectivedate": "Ablaufdatum bevor Gültigkeitsdaum",
  "license.firstbillingdateAfterExpirydate":
    "Erstes Rechnungsdatum nach Ablaufdatum",
  "expiration.heading": "Ablaufdatum",
  "expiration.description":
    "Wenn ein Ablaufdatum existiert, und das Datum erreicht wird, können sich die Benutzer dieses Mandanten nicht mehr im System anmelden.",
  "expiration.newButton": "Ablaufdatum anlegen",
  "expiration.deleteMessage":
    "Wenn dieses Ablaufdatum gelöscht wird, können sich alle aktiven Benutzer sofort wieder im System anmelden.",
  "expiration.updateOrCreateMessage":
    "Wenn das angegebene Datum der heutige Tag ist, oder in der Vergangeheit liegt, werden alle zurzeit aktiven Benutzer abgemeldet!",
  "non-unique-number": "Diese Kennung ist bereits in Verwendung.",
  activeUsers: "Aktive Nutzer",
  showclientlicensehistory: "Änderungsverlauf anzeigen",
  hideclientlicensehistory: "Änderungsverlauf verbergen",
}

export default messages
