import React from "react"
import { PlannerPoolService } from "../../configuration/PlannerPoolService"
import { MILLISECONDSTORETURNSHIFT } from "./MILLISECONDSTORETURNSHIFT"
import { MINIMUMDAYSSINTHEFUTURE } from "./MINIMUMDAYSSINTHEFUTURE"

interface Props {
  employeeId?: string
}

type AnyDateParm = Date | string | number

interface UsePermissions {
  checkJustCreated: (timestamp: AnyDateParm) => boolean
  checkDateInAccessibleRange: (date: string) => boolean
  ignore: boolean
}

const plannerPoolService = new PlannerPoolService()

export default function usePermissions({ employeeId }: Props): UsePermissions {
  const [ignore, setIgnore] = React.useState(false)
  React.useEffect(() => {
    void plannerPoolService
      .isAllowedToUsePlannerPoolIndiscriminately()
      .then(setIgnore)
  }, [employeeId])
  const checkDateInAccessibleRange = React.useCallback(
    (date: string) => ignore || isInTheFuture(date),
    [ignore]
  )
  const checkJustCreated = React.useCallback(
    (timestamp: AnyDateParm) => ignore || justCreated(timestamp),
    [ignore]
  )
  return { ignore, checkDateInAccessibleRange, checkJustCreated }
}

function isInTheFuture(date: string, maxDays: number = 28): boolean {
  const now = new Date()
  // Construction must be identical to avoid timezone slip-ups:
  const today = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + MINIMUMDAYSSINTHEFUTURE
  ).getTime()
  const maxDate = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + maxDays
  ).getTime()
  const [y, m, d] = date.split("-").map(x => parseInt(x, 10))
  const target = new Date(y, m - 1, d).getTime()
  return target >= today && (maxDate === undefined || maxDate >= target)
}

function justCreated(lastChangeTimestamp: AnyDateParm): boolean {
  // if (!userId || !lastChangeUserId) {
  //   return false
  // }
  const now = new Date()
  const nowTime = now.getTime()
  const timestamp = new Date(lastChangeTimestamp).getTime()
  const distance = nowTime - timestamp
  return distance < MILLISECONDSTORETURNSHIFT // && userId === lastChangeUserId
}
