/**
 * NOTE:
 * This code is based on the Planner module's code! It should probably be
 * optimized for use in the mobile application.
 *
 * It's primary purpose is to reload the planner when a change to the pool
 * has been effected by the employee.
 *
 * Throttling heuristics have been copied from the planner, since we'll also
 * react to planner changes from the same browser.
 */

import React from 'react'
import { subscribeToServiceWorkerMessage } from '../../shared/serviceWorker'

const HEURISTICSPASTERE = /paste/gi
const THROTTLETIMEOUT = 2000

export default function useAsyncServiceWorker(
  reloadPlanner: () => Promise<void>
): AsyncServiceWorkerHook {
  const [loadingRows, setLoadingRows] = React.useState<string[]>([])
  const [result, setResult] = React.useState<AsyncServiceWorkerHook>({
    loadingRows: [],
    mostRecentAutoplanChangeEmployeeIds: [],
  })
  React.useEffect(() => {
    if (loadingRows.length > 0) {
      setResult(r => ({ ...r, loadingRows }))
    }
  }, [loadingRows])
  const handleServiceWorkerPollingStartedMessage = React.useCallback(
    (data: {
      type: string
      correlationId: string
      requestJSON: Record<string, any>
    }) => {
      const userIds = data.requestJSON.userIds ?? [data.requestJSON.userId]
      if (userIds.length > 0 && userIds[0] !== undefined) {
        setLoadingRows(r => (
          [...r, ...userIds]
        ))
      }
    }, [])
  const shouldRequestReloadBeThrottled = React.useCallback((data: {
    requestJSON: Record<string, any>
  }) => {
    // WARNING: THIS IS JUST HEURISTICS-BASED!
    const r = data.requestJSON
    return (r.userIds ?? []).length > 1 // multiple users
      || (r.targets ?? []).length > 1 // multiple target days
      || (r.days ?? []).length > 1 // multiple target days
      || (r.cancelAutoplanDays ?? []).length > 1 // multiple target days
      || (r.activityDays ?? []).length > 1 // multiple target days
      || (r.unavailabilities ?? []).length > 1 // multiple target days
      || (r.commandName ?? '').match(HEURISTICSPASTERE) // any paste op
  }, [])
  const handleServiceWorkerChangeMessage = React.useCallback((data: {
    type: string
    aggregator: string
    correlationId: string
    url?: string
    requestJSON: Record<string, any>
  }) => {
    if (
      data.aggregator.startsWith("loadPlanner") // daylogger AND autoplan
      || data.aggregator.startsWith("activityplanner")
      || data.aggregator.startsWith("unavailabilityplanner")
    ) {
      const shouldThrottle = shouldRequestReloadBeThrottled(data)
      const userIds = data.requestJSON.userIds ?? [data.requestJSON.userId]
      const s: Partial<AsyncServiceWorkerHook> = data.url?.includes("/rest/autoplan/")
        ? { mostRecentAutoplanChangeEmployeeIds: userIds }
        : {}
      const go = () => {
        setResult(r => ({ ...r, ...s }))
        setLoadingRows(r => (
          r.filter(
            id => id !== undefined && !userIds.includes(id)
          )
        ))
      }
      if (shouldThrottle) {
        setTimeout(go, THROTTLETIMEOUT)
      } else {
        go()
      }
    } else if (data.aggregator) {
      console.log('No use for this aggregator message:', data)
    }
  }, [shouldRequestReloadBeThrottled])
  const loadingRowsDependency = React.useRef<string[]>(loadingRows)
  React.useEffect(() => {
    const loadingRowsChanged = loadingRowsDependency.current !== loadingRows
    loadingRowsDependency.current = loadingRows
    if (loadingRowsChanged && loadingRows.length === 0) {
      void reloadPlanner().then(() => {
        setResult(r => ({ ...r, loadingRows }))
      })
    }
  }, [loadingRows, reloadPlanner])
  React.useEffect(
    () => {
      const unsubServiceWorkerChanged = subscribeToServiceWorkerMessage(
        "async-state-changed",
        handleServiceWorkerChangeMessage
      )
      const unsubServiceWorkerPollingStarted = subscribeToServiceWorkerMessage(
        "async-state-polling-started",
        handleServiceWorkerPollingStartedMessage
      )
      return () => {
        unsubServiceWorkerChanged()
        unsubServiceWorkerPollingStarted()
      }
    },
    [handleServiceWorkerChangeMessage, handleServiceWorkerPollingStartedMessage]
  )
  return result
}

export interface AsyncServiceWorkerHook {
  loadingRows: EmployeeId[]
  mostRecentAutoplanChangeEmployeeIds: EmployeeId[]

}

type EmployeeId = string