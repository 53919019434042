import * as React from "react"
import type PlannerData from "../../configuration/PlannerData"
import { useIntl } from "react-intl"

interface UseMobilePoolCallbacks {
  returnToPool: () => void
}

export default function useMobilePoolReturnShiftCallback({
  date,
  identifier,
  plannerData,
  triggerReload,
}: {
  plannerData?: PlannerData
  date?: string
  identifier?: string
  triggerReload: () => void
}): UseMobilePoolCallbacks {
  const intl = useIntl()
  const returnToPool = React.useCallback(() => {
    if (date !== undefined && identifier !== undefined) {
      void plannerData?.returnOneToPool(date, identifier).then(success => {
        if (success) {
          triggerReload()
        } else {
          alert(intl.formatMessage({ id: "pool-mobile-return-failed" }))
        }
      })
    }
  }, [plannerData, date, identifier, triggerReload, intl])
  return {
    returnToPool,
  }
}
