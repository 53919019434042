import React from 'react'
import { PunchAPI } from '../configuration/PunchAPI'
import { PlannerPoolService } from '../configuration/PlannerPoolService'

interface Props {
  username?: string
}

interface UsePermissionsForVisibility {
  isAllowedToPunchIn: boolean
  isAllowedToUsePlannerPool: boolean
}

export function usePermissionsForVisibility({ username }: Props): UsePermissionsForVisibility {
  const [isAllowedToPunchIn, setIsAllowedToPunchIn] = React.useState(false)
  const [isAllowedToUsePlannerPool, setIsAllowedToUsePlannerPool] =
    React.useState(false)
  React.useEffect(() => {
    void new PunchAPI().isAllowedToPunchIn().then(setIsAllowedToPunchIn)
    void new PlannerPoolService()
      .isAllowedToUsePlannerPool()
      .then(setIsAllowedToUsePlannerPool)
  }, [username])
  return { isAllowedToPunchIn, isAllowedToUsePlannerPool }
}