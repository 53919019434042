import { DesktopOutlined, LogoutOutlined } from "@ant-design/icons"
import * as React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import type { Settings } from "../utils/SettingsPanel"
import SettingsPanel from "../utils/SettingsPanel"
import { ICONS, POOL, PUNCH, REQUESTS, ROUTES } from "../routing/routing"
import { usePermissionsForVisibility } from "../hooks/usePermissionsForVisibility"

export interface Props {
  /**
   * This is a controlled component - the menu is opened
   * "from the outside":
   */
  open: boolean

  displayModule?: string
  setDisplayModule: (s: string) => void

  logout: () => void

  vacationRequestsAvailable: boolean

  username: string

  saveUserSettings: (settings: Settings) => Promise<void>
  loadUserSettings: () => Promise<Settings>
}

export default function MobileSideMenu(props: Props): JSX.Element | null {
  const intl = useIntl()
  const { isAllowedToPunchIn, isAllowedToUsePlannerPool } =
    usePermissionsForVisibility({ username: props.username })
  if (props.open) {
    const navigate = (r: string) => props.setDisplayModule(r)
    return (
      <div className="mobile-side-menu">
        <div className="mobile-side-menu-routes">
          {ROUTES.map(r => {
            let cls = "mobile-side-menu-entry"
            let nav = () => navigate(r)
            if (r === props.displayModule) {
              cls = `${cls} mobile-current`
            }
            if (
              (r === REQUESTS && !props.vacationRequestsAvailable) ||
              (r === PUNCH && !isAllowedToPunchIn) ||
              (r === POOL && !isAllowedToUsePlannerPool)
            ) {
              cls = `${cls} mobile-disabled`
              nav = () => undefined
            }
            return (
              <div key={r} className={cls} onClick={nav}>
                {ICONS[r]} {intl.formatMessage({ id: r })}
              </div>
            )
          })}
          <div
            className="mobile-side-menu-entry desktop-link"
            onClick={() => {
              window.document.location = "/"
            }}
          >
            <DesktopOutlined /> {intl.formatMessage({ id: "desktop-link" })}
          </div>
          <SettingsPanel
            saveUserSettings={props.saveUserSettings}
            loadUserSettings={props.loadUserSettings}
          ></SettingsPanel>
        </div>
        <div
          className="mobile-side-menu-entry mobile-side-menu-logout"
          onClick={props.logout}
        >
          <LogoutOutlined></LogoutOutlined>{" "}
          <div className="mobile-logout-content">
            <FormattedMessage id="Logout"></FormattedMessage>
            <div className="mobile-username">{props.username}</div>
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}
