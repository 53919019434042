import { get } from "../requestUtils"

export async function plannerPoolEnabled(clientId?: string): Promise<boolean> {
  const { enabled } = await get<{ enabled: boolean }>(
    "/rest/clientfeatureconfiguration/plannerpoolenabled",
    clientId ? {
      client_id: clientId,
    } : undefined
  )
  return enabled
}