/* The login modal component */

import React from "react"
import { Modal } from "antd"

import LoginFormContainer from "../loginFormWithOTP/LoginFormApp"
import "./css/login.css"

/**
 * LoginModal modal component
 */
const LoginModal = props => {
  const { onCancel, openLoginDialog } = props
  const getContainer = React.useCallback(
    () => document.getElementById("root"),
    []
  )
  const style = React.useMemo(
    () => ({
      top: "calc(100vh / 4)",
    }),
    []
  )
  const maskStyle = React.useMemo(
    () => ({
      backgroundImage: "url(/mina-login-bg.svg)",
      backgroundSize: "cover",
      backgroundColor: "rgba(255,255,255,1)",
      // top: '60px'
    }),
    []
  )
  return (
    <Modal
      getContainer={getContainer}
      className="login-modal"
      onCancel={onCancel}
      closable={false}
      open={openLoginDialog}
      footer={null}
      style={style}
      maskStyle={maskStyle}
    >
      <LoginFormContainer />
    </Modal>
  )
}

export default LoginModal
