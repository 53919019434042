const messages = {
  "usermenu.label.configure-client-settings":
    "Organisationsweite Einstellungen",
  "token-error-reused":
    "Das 2fa-Token wurde bereits verwendet. Kontaktieren Sie einen Administrator",
  "token-error-too-fast":
    "Zu viele Versuche in zu kurzer Zeit: Bitte warten Sie mindestens 30s, um es noch einmal zu versuchen",
  "token-error-incorrect": "Das 2fa-Token ist abgelaufen oder inkorrekt",
  "token-error-unknown":
    // eslint-disable-next-line max-len
    "Bei der Überprüfung des 2fa-Tokens ist ein unbekannter Fehler aufgetreten; bitte versuchen Sie es später noch einmal",
  otpsetup: "2-Faktor-Authentifizierung",
  secondfactor: "Zweiter Faktor",
  "enter-second-factor":
    "Bitte geben Sie hier den zweiten Faktor aus Ihrer Authenticator-App ein",
  username: "Benutzername",
  password: "Passwort",
  login: "Anmelden",
  logoutUser: `{username} abmelden`,
  loggedInAs: `Angemeldet als {username}`,
  logout: `Abmelden`,
  "login.failed": "Anmeldung fehlgeschlagen",
  usersettings: "Persönliche Einstellungen",
  changePassword: "Passwort ändern",
  passwordForgotten: "Passwort vergessen?",
  passwordReset: "Passwort zurücksetzen",
  passwordResetLockNote:
    "Bitte beachten: Das Passwort kann nur alle zehn Minuten zurückgesetzt werden!",
  send: "Abschicken",
  "Reset mail sent.": "Passwort-Mail wurde versendet.",
  "Check your mail and spam folders.":
    "Schauen Sie in Ihr Postfach und Spam Ordner.",
  "A problem with the username occurred":
    "Es gab ein Problem mit dem angegebenen Benutzernamen",
  userNotFound:
    "Benutzer mit dem Benutzernamen {username} wurde nicht gefunden.",
  usernameMissing: "Es wurde kein Benutzername angegeben.",
  passwordMissing: "Es wurde kein Passwort angegeben.",
  loginErrorHeader: "Fehler bei der Anmeldung",
  loginErrorMessage:
    // eslint-disable-next-line max-len
    "Bitte prüfen Sie Ihren Benutzernamen und das eingegebene Passwort auf Groß/Kleinschreibung oder verwenden Sie das Passwort-vergessen? Formular.",
  problemReportUserMenu: "Problembericht",
  clearProblemReportUserMenu: "Problembericht leeren",
  "really-delete-problem-report":
    "Wollen Sie den Problembericht wirklich komplett löschen?",
  "mobile-link": "Mobilanwendung",
}

export default messages
