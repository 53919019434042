/* eslint-disable max-len */
const messages = {
  "calendarentrytypes.actitvity": "Tätigkeit",
  "calendarentrytypes.unavailability": "Nichtverfügbarkeit",
  "calendarentrytypes.name": "Name*",
  "calendarentrytypes.abbrv": "Abkürzung",
  "calendarentrytypes.color": "Farbe",
  "calendarentrytypes.unavailabilitytype": "Dieser Kalendereintragstyp ist ...",
  "calendarentrytypes.effectivedate": "Stichtag",
  "calendarentrytypes.allday": "Ganztägig",
  "calendarentrytypes.availableToEmployees": "Auswählbar für Mitarbeiter",
  "calendarentrytypes.visibleForEmployees": "Sichtbar für Mitarbeiter",
  "calendarentrytypes.restrictedfurther": "Erlaubnis erforderlich",
  "calendarentrytypes.importkey": "Import-Schlüssel",
  "calendarentrytypes.datevkey": "Bearbeitungs-Schlüssel (DATEV)",
  "calendarentrytypes.newActivityButton": "Tätigkeit",
  "calendarentrytypes.activities": "Tätigkeiten",
  "calendarentrytypes.unavailabilities": "Nichtverfügbarkeiten",
  "calendarentrytypes.newActivity": "Neue Tätigkeit",
  "calendarentrytypes.newActivityButtonTitle": "Neue Tätigkeit anlegen",
  "calendarentrytypes.newUnavailabilityButton": "Nichtverfügbarkeit",
  "calendarentrytypes.newUnavailabilityButtonTitle":
    "Neue Nichtverfügbarkeit anlegen",
  "calendarentrytypes.newUnavailability": "Neue Nichtverfügbarkeit",
  "calendarentrytypes.openEditCet": "TODO xxx",
  "calendarentrytypes.newundefined": "Nichts ausgewählt",
  "calendarentrytypes.unavailabilitytypesick": "Krankheit",
  "calendarentrytypes.unavailabilitytypevacation": "Urlaub",
  "calendarentrytypes.unavailabilitytypeovertimereduction": "Überstundenabbau",
  "calendarentrytypes.unavailabilitytypespecial": "Sonderurlaub",
  "calendarentrytypes.unavailabilitytypeother": "Anderer Typ",
  "calendarentrytypes.error": "Bitte die angezeigten Fehler beheben.",
  "calendarentrytypes.error.editing": "Bitte geben Sie einen Namen ein",
  "calendarentrytypes.error.missingName": "Bitte geben Sie einen Namen ein.",
  "calendarentrytypes.restrictedfurtherHelp": "Hier sollte ein Hilfetext sein",
  "cet.confirmDeleteHeader": "Löschen des Kalendereintragstyp bestätigen",
  "cet.confirmDeleteText":
    "Nach dem Löschen des Kalendereintragtyp wird sich das Verhalten/die Darstellung der bereits eingeplanten Mitarbeiter verändern!",
}
export default messages
