import userManagerTypes from "../redux/actions/actionTypes"
import usernameTypes from "../usernameEditForm/redux/actionTypes"

export default function broadcastUserChangeReducer(state = {}, action) {
  switch (action.type) {
    case userManagerTypes.CLOSEEDITUSERDIALOG:
      return setChangeDate(state)
    case userManagerTypes.CLOSEADDUSERDIALOG:
      return setChangeDate(state)
    case userManagerTypes.BATCHSUCCESS:
      return setChangeDate(state)
    case usernameTypes.LOADFEEDBACK:
      return setChangeDate(state)
    default:
      return state
  }
}

const setChangeDate = state => {
  return {
    ...state,
    lastUserChange: Date.now(),
  }
}
